import { useEffect, useState } from "react";
import "./AdminSpecificUser.scss";
import {
  IActivity,
  IBooking,
  ICoPlayerBooking,
  ICourt,
  IUser,
  IUserHistory,
} from "../../types/api";
import { capitalizeWords } from "../../shared/utility";
import { combinedQuery } from "../../api/combinedQueries/combinedQueries";

import Spinner from "../../components/UI/Spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { StatusEnum } from "../../shared/enums";
import { toast } from "react-toastify";
import SlotHistoryPopup from "../../containers/SlotHistory/SlotHistoryPopup/SlotHistoryPopup";
import React from "react";

interface IAdminSpecificUserProps {}

function AdminSpecificUser(props: IAdminSpecificUserProps) {
  const { memberId } = useParams<string>();
  const [userHistory, setUserHistory] = useState<IUserHistory[] | undefined>();

  const [selectedBookingForHistory, setSelectedBookingForHistory] = useState<
    IBooking | undefined
  >();

  const [
    selectedCoPlayerBookingForHistory,
    setSelectedCoPlayerBookingForHistory,
  ] = useState<ICoPlayerBooking | undefined>();
  const [
    selectedActivityBookingForHistory,
    setSelectedActivityBookingForHistory,
  ] = useState<IActivity | undefined>();

  const [showSlotHistoryPopupCoPlayer, setShowSlotHistoryPopupCoPlayer] =
    useState<boolean>(false);
  const [showSlotHistoryPopupActivity, setShowSlotHistoryPopupActivity] =
    useState<boolean>(false);

  const [showSlotHistoryPopup, setShowSlotHistoryPopup] =
    useState<boolean>(false);

  const [currentHistoryType, setCurrentHistoryType] = useState<
    "bookings" | "activities" | "co-player-booking" | "user-history"
  >("bookings");
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(false);
  const [bookings, setBookings] = useState<IBooking[]>([]);
  const [coPlayerBookings, setCoPlayerBookings] = useState<ICoPlayerBooking[]>(
    []
  );
  const [activityBookings, setActivityBookings] = useState<IActivity[]>([]);
  const [activeActivityBookings, setActiveActivityBookings] = useState<
    IActivity[]
  >([]);
  const [courts, setCourts] = useState<ICourt[]>([]);

  const fetchActiveActivitiesForUserView = async () => {
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        member_id: memberId,
        active: true,
        fetchActiveActivities: true,
      });

      if (
        response.activeActivities &&
        !("message" in response.activeActivities)
      ) {
        const activityBookings = response.activeActivities.sort(
          (a: IActivity, b: IActivity) =>
            new Date(b.datetime_start).getTime() -
            new Date(a.datetime_start).getTime()
        );
        setActiveActivityBookings(activityBookings);
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  const fetchChosenItems = async ({
    fetchUserData = false,
    fetchBookingsByMember = false,
    fetchCoPlayerBookingsByMember = false,
    fetchCourts = false,
    fetchActiveActivities = false,
    fetchUserHistory = false,
  }) => {
    setLoading(true);

    try {
      const response = await combinedQuery({
        requestor: "admin",
        member_id: memberId,
        fetchUserData: fetchUserData,
        active: false,
        fetchBookingsByMember: fetchBookingsByMember,
        fetchCoPlayerBookingsByMember: fetchCoPlayerBookingsByMember,
        fetchActiveActivities: fetchActiveActivities,
        fetchCourts: fetchCourts,
        fetchUserHistory: fetchUserHistory,
      });

      if (
        fetchUserData &&
        response.userData &&
        !("message" in response.userData)
      ) {
        setUser(response.userData);
      }
      if (
        fetchUserHistory &&
        response.userHistory &&
        !("message" in response.userHistory)
      ) {
        setUserHistory(response.userHistory);
      }
      if (
        fetchBookingsByMember &&
        response.bookingsByMember &&
        !("message" in response.bookingsByMember)
      ) {
        const bookings = response.bookingsByMember.sort(
          (a: IBooking, b: IBooking) =>
            new Date(b.datetime_start).getTime() -
            new Date(a.datetime_start).getTime()
        );

        setBookings(bookings);
      }
      if (
        fetchCoPlayerBookingsByMember &&
        response.coPlayerBookingsByMember &&
        !("message" in response.coPlayerBookingsByMember)
      ) {
        const bookings = response.coPlayerBookingsByMember.sort(
          (a: ICoPlayerBooking, b: ICoPlayerBooking) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        setCoPlayerBookings(bookings);
      }
      if (fetchCourts && response.courts && !("message" in response.courts)) {
        setCourts(response.courts);
      }

      if (
        fetchActiveActivities &&
        response.activeActivities &&
        !("message" in response.activeActivities)
      ) {
        const activityBookings = response.activeActivities.sort(
          (a: IActivity, b: IActivity) =>
            new Date(b.datetime_start).getTime() -
            new Date(a.datetime_start).getTime()
        );

        setActivityBookings(activityBookings);
      }

      setLoading(false);
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchUserData: true,
      fetchBookingsByMember: true,
      fetchCoPlayerBookingsByMember: true,
      fetchCourts: true,
      fetchActiveActivities: true,
      fetchUserHistory: true,
    });
    fetchActiveActivitiesForUserView();
  }, []);

  useEffect(() => {
    if (selectedBookingForHistory) {
      setShowSlotHistoryPopup(true);
      return;
    }
    if (
      selectedCoPlayerBookingForHistory &&
      selectedCoPlayerBookingForHistory.date
    ) {
      setShowSlotHistoryPopupCoPlayer(true);
      return;
    }
    if (selectedActivityBookingForHistory) {
      setShowSlotHistoryPopupActivity(true);
      return;
    }
  }, [
    selectedBookingForHistory,
    selectedCoPlayerBookingForHistory,
    selectedActivityBookingForHistory,
  ]);

  const handleToggleHistoryType = (
    type: "bookings" | "activities" | "co-player-booking" | "user-history"
  ) => {
    setCurrentHistoryType(type);
  };
  const getBookingStatusClass = (status: string) => {
    switch (status) {
      case StatusEnum.CANCELLED:
        return "cancelled";
      case StatusEnum.CHECKED_IN:
        return "checked-in-specific";
      case StatusEnum.CONFIRMED:
        return "confirmed";
      case StatusEnum.SEMI_NO_SHOW:
        return "semi-no-show";
      case StatusEnum.NO_SHOW:
        return "no-show";
      case StatusEnum.PAID:
        return "paid";
      default:
        return "";
    }
  };
  const upcomingBookings = bookings.filter(
    (booking) => new Date(booking.datetime_start) >= new Date()
  );

  const pastBookings = bookings.filter(
    (booking) => new Date(booking.datetime_start) < new Date()
  );

  const upcomingCoPlayerBookings = coPlayerBookings.filter(
    (booking) => new Date(booking.date) >= new Date()
  );

  const pastCoPlayerBookings = coPlayerBookings.filter(
    (booking) => new Date(booking.date) < new Date()
  );

  const upcomingActivityBookings = activeActivityBookings;
  const pastActivityBookings = activityBookings.filter(
    (activity) => new Date(activity.datetime_start) < new Date()
  );
  if (!user || loading) return <Spinner />;
  return (
    <div className="admin-handle-specific-user-layout">
      <a className="back-button text-m" onClick={() => navigate("/medlemmar")}>
        Tillbaka
      </a>
      <h1 className="text-l primary-text ">
        Historik för{" "}
        {capitalizeWords(user.first_name) +
          " " +
          capitalizeWords(user.last_name)}
        {user.frozen && <span className="frozen"> - medlem fryst</span>}
      </h1>
      <div className="handle-specific-user-actions-wrapper">
        <div className="history-toggle-wrapper">
          <button
            className={`toggle-button text-m ${
              currentHistoryType === "bookings" ? "active" : ""
            }`}
            onClick={() => handleToggleHistoryType("bookings")}
          >
            Bokningar
          </button>

          <button
            className={`toggle-button text-m ${
              currentHistoryType === "activities" ? "active" : ""
            }`}
            onClick={() => handleToggleHistoryType("activities")}
          >
            Aktiviteter
          </button>

          <button
            className={`toggle-button text-m ${
              currentHistoryType === "co-player-booking" ? "active" : ""
            }`}
            onClick={() => handleToggleHistoryType("co-player-booking")}
          >
            Bokningar som medspelare
          </button>

          <button
            className={`toggle-button text-m ${
              currentHistoryType === "user-history" ? "active" : ""
            }`}
            onClick={() => handleToggleHistoryType("user-history")}
          >
            Användarhistorik
          </button>
        </div>
        <div className="users-add-wrapper"></div>
      </div>
      <table className="history-table">
        <thead>
          <tr>
            <th className="text-m secondary-text">Datum & tid</th>

            {currentHistoryType === "bookings" ? (
              <>
                <th className="text-m secondary-text">Bana</th>
                <th className="text-m secondary-text">Status på bokning</th>
                <th className="text-m secondary-text">Spelare</th>
                <th className="text-m secondary-text">Kommentar</th>
              </>
            ) : currentHistoryType === "co-player-booking" ? (
              <>
                <th className="text-m secondary-text">Bana</th>
                <th className="text-m secondary-text">Status på bokning</th>
                <th className="text-m secondary-text">Huvudbokare</th>
              </>
            ) : currentHistoryType === "activities" ? (
              <>
                <th className="text-m secondary-text">Bana</th>
                <th className="text-m secondary-text">Aktivitet</th>
                <th className="text-m secondary-text">Max antal spelare</th>
                <th className="text-m secondary-text">Färg</th>
              </>
            ) : (
              currentHistoryType === "user-history" && (
                <>
                  <th className="text-m secondary-text">Händelse</th>
                  <th className="text-m secondary-text">Utförd av</th>
                </>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {/* Bokningar */}
          {currentHistoryType === "bookings" && (
            <>
              {/* Kommande bokningar */}
              <tr className="non-clickable">
                <td className="text-m upcoming-bookings-header">
                  Kommande bokningar
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {upcomingBookings.length > 0 ? (
                upcomingBookings.map((booking) => {
                  const courtData = courts.find(
                    (court) => booking.court_id === court.court_id
                  );
                  const playerAmount =
                    booking.co_players.length +
                    booking.guests.length +
                    1 +
                    booking.coach_id.length;

                  return (
                    <tr
                      key={booking.booking_id}
                      className="text-m"
                      onClick={() => setSelectedBookingForHistory(booking)}
                    >
                      <td>
                        {booking.datetime_start.slice(0, 10)} ---{" "}
                        {booking.datetime_start.slice(11, 16)}
                      </td>
                      <td>{courtData?.name}</td>
                      <td className={getBookingStatusClass(booking.status)}>
                        {booking.status}
                      </td>
                      <td>{playerAmount}</td>
                      <td>{booking.comment}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-m no-bookings">-</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}

              {/* Passerade bokningar */}
              <tr className="non-clickable">
                <td className="text-m past-bookings-header">
                  Passerade bokningar
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {pastBookings.length > 0 ? (
                pastBookings.map((booking) => {
                  const courtData = courts.find(
                    (court) => booking.court_id === court.court_id
                  );
                  const playerAmount =
                    booking.co_players.length +
                    booking.guests.length +
                    1 +
                    booking.coach_id.length;

                  return (
                    <tr
                      key={booking.booking_id}
                      className="text-m"
                      onClick={() => setSelectedBookingForHistory(booking)}
                    >
                      <td>
                        {booking.datetime_start.slice(0, 10)} ---{" "}
                        {booking.datetime_start.slice(11, 16)}
                      </td>
                      <td>{courtData?.name}</td>
                      <td className={getBookingStatusClass(booking.status)}>
                        {booking.status}
                      </td>
                      <td>{playerAmount}</td>
                      <td>{booking.comment}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-m no-bookings">-</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </>
          )}

          {/* Bokningar som medspelare */}
          {currentHistoryType === "co-player-booking" && (
            <>
              {/* Kommande bokningar som medspelare */}
              <tr className="non-clickable">
                <td className="text-m upcoming-bookings-header">
                  Kommande bokningar som medspelare
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {upcomingCoPlayerBookings.length > 0 ? (
                upcomingCoPlayerBookings.map((booking) => {
                  const courtData = courts.find(
                    (court) => booking.court_id === court.court_id
                  );

                  return (
                    <tr
                      key={booking.PK}
                      className="text-m"
                      onClick={() =>
                        setSelectedCoPlayerBookingForHistory(booking)
                      }
                    >
                      <td>
                        {booking.date.slice(0, 10)} ---{" "}
                        {booking.date.slice(11, 16)}
                      </td>
                      <td>{courtData?.name}</td>
                      <td className={getBookingStatusClass(booking.status)}>
                        {booking.status}
                      </td>
                      <td>{capitalizeWords(booking.booker_name)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-m no-bookings">-</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}

              {/* Passerade bokningar som medspelare */}
              <tr className="non-clickable">
                <td className="text-m past-bookings-header">
                  Passerade bokningar som medspelare
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {pastCoPlayerBookings.length > 0 ? (
                pastCoPlayerBookings.map((booking) => {
                  const courtData = courts.find(
                    (court) => booking.court_id === court.court_id
                  );

                  return (
                    <tr
                      key={booking.PK}
                      className="text-m"
                      onClick={() =>
                        setSelectedCoPlayerBookingForHistory(booking)
                      }
                    >
                      <td>
                        {booking.date.slice(0, 10)} ---{" "}
                        {booking.date.slice(11, 16)}
                      </td>
                      <td>{courtData?.name}</td>
                      <td className={getBookingStatusClass(booking.status)}>
                        {booking.status}
                      </td>
                      <td>{capitalizeWords(booking.booker_name)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-m no-bookings">-</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </>
          )}

          {/* Aktiviteter */}
          {currentHistoryType === "activities" && (
            <>
              {/* Kommande aktiviteter */}
              <tr className="non-clickable">
                <td className="text-m upcoming-bookings-header">
                  Kommande aktiviteter
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {upcomingActivityBookings.length > 0 ? (
                upcomingActivityBookings.map((activity, index) => {
                  const courtData = courts.find(
                    (court) => activity.court_id[0] === court.court_id
                  );
                  return (
                    <tr
                      key={index}
                      className="text-m"
                      onClick={() =>
                        setSelectedActivityBookingForHistory(activity)
                      }
                    >
                      <td>
                        {activity.datetime_start.slice(0, 10)} ---{" "}
                        {activity.datetime_start.slice(11, 16)}
                      </td>
                      <td>{courtData?.name}</td>
                      <td>{activity.name.split("%-%")[0]}</td>
                      <td>{activity.participants_limit}</td>
                      <td>
                        <div
                          className={`activity-color-blob ${activity.color}`}
                        ></div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-m no-bookings">-</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}

              {/* Passerade aktiviteter */}
              <tr className="non-clickable">
                <td className="text-m past-bookings-header">
                  Passerade aktiviteter
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {pastActivityBookings.length > 0 ? (
                pastActivityBookings.map((activity, index) => {
                  const courtData = courts.find(
                    (court) => activity.court_id[0] === court.court_id
                  );
                  return (
                    <tr
                      key={index}
                      className="text-m"
                      onClick={() =>
                        setSelectedActivityBookingForHistory(activity)
                      }
                    >
                      <td>
                        {activity.datetime_start.slice(0, 10)} ---{" "}
                        {activity.datetime_start.slice(11, 16)}
                      </td>
                      <td>{courtData?.name}</td>
                      <td>{activity.name.split("%-%")[0]}</td>
                      <td>{activity.participants_limit}</td>
                      <td>
                        <div
                          className={`activity-color-blob ${activity.color}`}
                        ></div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-m no-bookings">-</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </>
          )}

          {currentHistoryType === "user-history" && userHistory && (
            <>
              <tr className="non-clickable">
                <td className="text-m user-history-header">Användarhistorik</td>
                <td></td>
                <td></td>
              </tr>
              {userHistory?.length > 0 ? (
                userHistory.map((history, index) => (
                  <tr key={index} className="text-m">
                    <td>
                      {history.created_at.slice(0, 10)} ---{" "}
                      {history.created_at.slice(11, 16)}
                    </td>
                    <td>{history.event}</td>
                    <td>{history.created_by}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-m no-bookings">
                    Ingen användarhistorik
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>

      <SlotHistoryPopup
        showPopup={showSlotHistoryPopup}
        onClose={() => {
          setShowSlotHistoryPopup(false);
          setSelectedBookingForHistory(undefined);
        }}
        onConfirm={() => console.log("confirm")}
        court_id={selectedBookingForHistory?.court_id || ""}
        start_time={
          selectedBookingForHistory?.datetime_start.split("T")[1] || ""
        }
        filterDate={
          new Date(selectedBookingForHistory?.datetime_start || new Date())
        }
        courts={courts}
      />
      <SlotHistoryPopup
        showPopup={showSlotHistoryPopupCoPlayer}
        onClose={() => {
          setShowSlotHistoryPopupCoPlayer(false);
          setSelectedCoPlayerBookingForHistory(undefined);
        }}
        onConfirm={() => console.log("confirm")}
        court_id={selectedCoPlayerBookingForHistory?.court_id || ""}
        start_time={selectedCoPlayerBookingForHistory?.date.split("T")[1] || ""}
        filterDate={
          new Date(selectedCoPlayerBookingForHistory?.date || new Date())
        }
        courts={courts}
      />
      <SlotHistoryPopup
        showPopup={showSlotHistoryPopupActivity}
        onClose={() => {
          setShowSlotHistoryPopupActivity(false);
          setSelectedActivityBookingForHistory(undefined);
        }}
        onConfirm={() => console.log("confirm")}
        court_id={selectedActivityBookingForHistory?.court_id[0] || ""}
        start_time={
          selectedActivityBookingForHistory?.datetime_start.split("T")[1] || ""
        }
        filterDate={
          new Date(
            selectedActivityBookingForHistory?.datetime_start || new Date()
          )
        }
        courts={courts}
      />
    </div>
  );
}

export default AdminSpecificUser;
