import {
  ICoach,
  ICoachAvailability,
  ICourt,
  ICourtType,
  IGraphError,
} from "../../types/api";
import {
  getCoachAvailibilityWeeklyQueryFn,
  getCoachByIDQueryFn,
} from "../coaches/coachesQueries";
import { API, Auth } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import { listCourtsQueryFn } from "../courts/courtsQueries";
import { IFacility } from "../../types/facility";
import { CourtTypeEnum } from "../../shared/enums";
import { getFacilityOpeningHoursQueryFn } from "../facility/facilityOpeningTimesQueries";

type coachAvailibilityWeeklyResponse = ICoachAvailability[] | IGraphError;
type coachDataResponse = ICoach | IGraphError;
type courtsResponse = ICourt[] | IGraphError;
type FacilityResponse = IFacility | IGraphError;

interface FetchDataResult {
  coachAvailabilityWeekly?: coachAvailibilityWeeklyResponse;
  coachData?: coachDataResponse;
  courts?: courtsResponse;
  facility?: FacilityResponse;
}

interface CombinedForCoachQueryParams {
  member_id: string;
  date: string;
  fetchCoachAvailabilityWeekly?: boolean;
  fetchCoachData?: boolean;
  fetchCourts?: boolean;
  fetchFacility?: boolean;
  court_type?: ICourtType;
}

export const combinedForCoachQuery = async ({
  member_id,
  date,
  court_type,
  fetchCoachAvailabilityWeekly = false,
  fetchCoachData = false,
  fetchCourts = false,
  fetchFacility = false,
}: CombinedForCoachQueryParams): Promise<FetchDataResult> => {
  let queryParts = [];
  let queryVariables = {};
  let variableDefinitions = [];

  if (fetchCoachAvailabilityWeekly) {
    const { query, inputs, variables } = getCoachAvailibilityWeeklyQueryFn(
      member_id,
      date
    );
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchFacility && court_type) {
    const { query, inputs, variables } =
      getFacilityOpeningHoursQueryFn(court_type);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  if (fetchCourts) {
    const { query } = listCourtsQueryFn();
    queryParts.push(query);
  }

  if (fetchCoachData) {
    const { query, inputs, variables } = getCoachByIDQueryFn(member_id);
    queryParts.push(query);
    queryVariables = { ...queryVariables, ...variables };
    variableDefinitions.push(inputs);
  }
  let variablesString = variableDefinitions.join(", ");
  let wrappedVariables =
    variableDefinitions.length > 0 ? `(${variablesString})` : "";
  let combinedQuery = `query CombinedQuery${wrappedVariables} {
    ${queryParts.join("\n")}
  }`;

  try {
    await Auth.currentSession();
    const getAllResponse = (await API.graphql({
      query: combinedQuery,
      variables: queryVariables,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const result: FetchDataResult = {};
    if (fetchCoachData && member_id) {
      const coachDataResponse = getAllResponse.data.getCoachByID;
      if ("message" in coachDataResponse) {
        toast.error(coachDataResponse.message);

        result.coachData = coachDataResponse as IGraphError;
      } else {
        result.coachData = coachDataResponse as ICoach;
      }
    }
    if (fetchFacility) {
      const facilityResponse = getAllResponse.data.getFacilityOpeningHours;
      if ("message" in facilityResponse) {
        toast.error(facilityResponse.message);
        result.facility = facilityResponse as IGraphError;
      } else {
        result.facility = facilityResponse as IFacility;
      }
    }
    if (fetchCoachAvailabilityWeekly && member_id && date) {
      const coachAvailabilityWeeklyResponse =
        getAllResponse.data.getCoachAvailabilityWeekly;

      if ("message" in coachAvailabilityWeeklyResponse) {
        toast.error(coachAvailabilityWeeklyResponse.message);
        result.coachAvailabilityWeekly =
          coachAvailabilityWeeklyResponse as IGraphError;
      } else {
        result.coachAvailabilityWeekly =
          coachAvailabilityWeeklyResponse as ICoachAvailability[];
      }
    }
    if (fetchCourts) {
      const courtsResponse = getAllResponse.data.listCourts;
      if ("message" in courtsResponse) {
        toast.error(courtsResponse.message);
        result.courts = courtsResponse as IGraphError;
      } else {
        result.courts = courtsResponse as ICourt[];
      }
    }
    return result;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
