import { compareAsc, lightFormat, setDay } from "date-fns";
import { Weekday } from "./enums";
import { formatToIsoDate } from "./dateUtils";

export function convertToNumberWithSpaces(num: number, ending?: string) {
  return `${Math.round(num)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${ending ? ending : ""}`;
}

export function convertToNumberWithSpacesDecimals(
  num: number,
  ending?: string
) {
  return `${num
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${ending ? ending : ""}`;
}
export function capitalizeWords(string: string) {
  if (!string) return "";
  return string
    .split(/(\s|-|´)/)
    .map((word) => {
      return word === " " || word === "-"
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
}

export function shortenNumber(number: number): string {
  if (number >= 1000000 || number <= -1000000) {
    return (number / 1000000).toFixed(1) + " mkr";
  } else if (number >= 100000 || number <= -100000) {
    return (number / 1000).toFixed(0) + " tkr";
  } else {
    return convertToNumberWithSpaces(number);
  }
}

export function getUnixTime(date: Date) {
  const timestamp = Math.floor(date.getTime() / 1000);

  return timestamp;
}

// export function isOnDev() {
//   return environment.appEnv === "development";
// }

export function convertToDropdown<T>({
  list,
  labelKey,
  valueKey,
}: {
  list: T[];
  labelKey: keyof T;
  valueKey: keyof T;
}) {
  return list.map((item) => ({
    label: `${item[labelKey]}`,
    value: `${item[valueKey]}`,
  }));
}

export function monthISO(month: string) {
  return `0${month}`.slice(-2);
}

/**
 * Get a date from a string format. If month date is not specifide it will be set to 1
 * IOS compatible
 * @param dateString Supported formats "2020-1-1" "2020-1"
 * @returns IOS compatible date
 */
export function stringToDate(dateString: string) {
  const dateArr = dateString.split("-");
  const [year, month, day] = dateArr;
  const date = new Date(+year, +month - 1, +day || 1, 0, 0, 0, 0);
  return date;
}

/**
 * Check if a date is after another date
 * @param date The date to check if it is after the compareDate
 * @param compareDate The date to compare to
 * @returns boolean
 */
export function isDateAfter(date: Date, compareDate: Date) {
  const r = compareAsc(date, compareDate);
  return r === 1 || r === 0;
}

export function formatKeyForCompare(dateKey: string, noFormat?: boolean) {
  if (noFormat) return dateKey;

  const splittedKey = dateKey.split("-");
  return `${+splittedKey[0] - 1}-${splittedKey[1]}`;
}

export function getLocale() {
  return navigator.language;
}
export function isValidPhoneNumber(phoneNr: string) {
  const regexPhoneNr = /^\+?[0-9]{1,3}?[0-9]{8,12}$/;
  return regexPhoneNr.test(phoneNr);
}

export function isValidEmail(email: string) {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexEmail.test(email);
}

export function getWeekdayFromDate(dateString: string): Weekday {
  const date = new Date(dateString);

  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 ? Weekday.Sunday : dayOfWeek - 1;
}

export function getWeekdayEngName(weekday: Weekday): string {
  switch (weekday) {
    case Weekday.Monday:
      return "monday";
    case Weekday.Tuesday:
      return "tuesday";
    case Weekday.Wednesday:
      return "wednesday";
    case Weekday.Thursday:
      return "thursday";
    case Weekday.Friday:
      return "friday";
    case Weekday.Saturday:
      return "saturday";
    case Weekday.Sunday:
      return "sunday";
  }
}

export function getWeekdaySwedishName(weekday: string): string {
  switch (weekday) {
    case "monday":
      return "Måndag";
    case "tuesday":
      return "Tisdag";
    case "wednesday":
      return "Onsdag";
    case "thursday":
      return "Torsdag";
    case "friday":
      return "Fredag";
    case "saturday":
      return "Lördag";
    case "sunday":
      return "Söndag";
    default:
      return "";
  }
}

export function makeWeekdayActive(dateString: string): Weekday {
  const currentWeekday = getWeekdayFromDate(dateString);
  return currentWeekday;
}

export function formatDateToSwedish(date: Date) {
  const newDate = new Date(date);

  const weekday =
    newDate
      .toLocaleString("sv-SE", { weekday: "long" })
      .charAt(0)
      .toUpperCase() +
    newDate.toLocaleString("sv-SE", { weekday: "long" }).slice(1);
  const dateAndMonth = newDate.toLocaleString("sv-SE", {
    day: "numeric",
    month: "long",
  });
  const hour = newDate
    .toLocaleString("sv-SE", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(":", ".");
  const seconds = newDate.getSeconds().toString().padStart(2, "0");
  return { weekday, dateAndMonth, hour, seconds };
}

export function getSwedishWeekday(dateString: string) {
  const date = new Date(dateString);
  const swedishWeekdays = [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
  ];
  return swedishWeekdays[date.getDay()];
}

export const combineDateTimeToUnix = (date: Date, time: string) => {
  const timeParts = time.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  date.setHours(hours, minutes, 0);

  return Math.floor(date.getTime() / 1000);
};
export function removeLeadingZeros(input: any) {
  if (input !== "0") {
    input = input.replace(/^0+/, "");
  }
  return input;
}
export const getSwedishColor = (color: string) => {
  switch (color) {
    case "blue":
      return "Blå";
    case "green":
      return "Grön";
    case "red":
      return "Röd";
    case "yellow":
      return "Gul";
    case "orange":
      return "Orange";
    case "purple":
      return "Lila";
    case "pink":
      return "Rosa";
    case "brown":
      return "Brun";
    case "black":
      return "Svart";
    case "white":
      return "Vit";
    case "grey":
      return "Grå";
    default:
      return color;
  }
};

export const getSwedishAdjectiveColor = (color: string) => {
  switch (color) {
    case "blue":
      return "Blåa";
    case "green":
      return "Gröna";
    case "red":
      return "Röda";
    case "yellow":
      return "Gula";
    case "orange":
      return "Orange";
    case "purple":
      return "Lila";
    case "pink":
      return "Rosa";
    case "brown":
      return "Bruna";
    case "black":
      return "Svarta";
    case "white":
      return "Vita";
    case "grey":
      return "Gråa";
    default:
      return color;
  }
};
export const generateHourlySlots = (start: string, end: string) => {
  let result = [];
  let startTime = new Date(`1970-01-01T${start}Z`);
  let endTime = new Date(`1970-01-01T${end}Z`);

  while (startTime < endTime) {
    const endTimeSlot = new Date(startTime.getTime() + 60 * 60 * 1000);
    result.push({
      start_time: formatToIsoDate(startTime).slice(11, 16),
      end_time: formatToIsoDate(endTimeSlot).slice(11, 16),
      booking: [],
    });
    startTime = endTimeSlot;
  }

  return result;
};

export const getFormatedDate = (date: Date) => {
  return lightFormat(date, "yyyy-MM-dd");
};

export const capFirstAndRemoveDash = (string: string) => {
  return string.charAt(0) + string.toLowerCase().slice(1).replace(/_/g, " ");
};
export const checkDateDash = (string: string) => {
  string = string.replace(/(\d\d)-/g, "$1");
  string = string.replace(/(\d{2})(?=\d)/g, "$1-");
  if (!/^[0-9-]+$/.test(string)) {
    return string.replace(string.charAt(string.length - 1), "");
  }
  return string;
};

export function debounce<F extends (...args: any[]) => void>(
  func: F,
  delay: number
) {
  let timeoutId: ReturnType<typeof setTimeout>;

  function debouncedFunction(...args: Parameters<F>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  }

  debouncedFunction.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  return debouncedFunction as F & { cancel: () => void };
}

export function checkPassword(password: string): string[] {
  const errors: string[] = [];
  const minLength = 8;

  if (password.length < minLength) {
    errors.push(`Lösenord måste vara minst ${minLength} karaktärer långt.`);
    return errors;
  }
  if (!/[a-z]/.test(password)) {
    errors.push("Lösenord måste innehålla minst en gemen.");
    return errors;
  }
  if (!/[A-Z]/.test(password)) {
    errors.push("Lösenord måste innehålla minst en versal.");
    return errors;
  }
  if (!/[0-9]/.test(password)) {
    errors.push("Lösenord måste innehålla minst ett nummer");
    return errors;
  }
  if (!/[\W_]/.test(password)) {
    errors.push("Lösenord måste innehålla minst en symbol.");
    return errors;
  }

  return errors;
}

export function formatPersonalNumber(input: string) {
  let ssn = input.replace(/\D/g, "");

  if (ssn.length > 6) {
    ssn = ssn.slice(0, 6) + "-" + ssn.slice(6);
  }

  return ssn;
}

export function formatPhoneNumber(input: string) {
  let phone = input.replace(/(?!^\+)\D/g, "");

  // if (phone.length < 3) {
  //   return "+46";
  // }

  // if (!phone.startsWith("+46")) {
  //   phone = "+46" + phone.slice(phone.startsWith("+") ? 1 : 0);
  // }

  if (phone.length > 3) {
    phone = phone.slice(0, 3) + " " + phone.slice(3);
  }
  if (phone.length > 6) {
    phone = phone.slice(0, 6) + "-" + phone.slice(6);
  }
  if (phone.length > 9) {
    phone = phone.slice(0, 9) + " " + phone.slice(9);
  }
  if (phone.length > 13) {
    phone = phone.slice(0, 13) + " " + phone.slice(13);
  }

  return phone;
}

export function hardcodeDateIsInRangeApr20Aug21(date: Date): boolean {
  const year = date.getFullYear();
  const august20 = new Date(year, 7, 20); // August 21st
  const april20 = new Date(year, 3, 20); // April 20th

  // If the date is in the range August 21st to December 31st
  if (date >= august20) {
    return false;
  }

  // If the date is in the range January 1st to April 20th
  if (date <= april20) {
    return false;
  }

  // If the date does not fall within the restricted range
  return true;
}

export function hardCodeDateIsInRangeApr01Oktober22(date: Date): boolean {
  // If the date is in the range April 1st to October 22nd
  if (
    date >= new Date(date.getFullYear(), 3, 1) &&
    date <= new Date(date.getFullYear(), 9, 22)
  ) {
    return true;
  }

  // If the date does not fall within the restricted range
  return false;
}
export const getMondayOfWeek = (date: Date): Date => {
  // Always adjust the date to the Monday of the week
  return setDay(date, 1, { weekStartsOn: 1 });
};

export const isDateInCurrentWeek = (date: Date): boolean => {
  const today = new Date();
  const mondayOfCurrentWeek = getMondayOfWeek(today);
  mondayOfCurrentWeek.setHours(0, 0, 0, 0); // Start of the week
  const sundayOfCurrentWeek = new Date(mondayOfCurrentWeek);
  sundayOfCurrentWeek.setDate(mondayOfCurrentWeek.getDate() + 6); // End of the week

  return date >= mondayOfCurrentWeek && date <= sundayOfCurrentWeek;
};
