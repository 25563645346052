import "./EditCoPlayersPopup.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import {
  IBooking,
  ICoach,
  ICourt,
  ICourtType,
  IGuest,
  IIndividualWithStatus,
  IPermission,
  IPrices,
  ISummaryItem,
  IUser,
} from "../../../types/api";
import { toast } from "react-toastify";
import { CognitoUser } from "../../../types/cognito";
import { capitalizeWords, formatDateToSwedish } from "../../../shared/utility";
import time from "../../../assets/images/time.svg";
import greenCheck from "../../../assets/images/green-check.svg";
import useBeforeUnload from "../../../shared/hooks/useBeforeUnload";
import singleCross from "../../../assets/images/single-corss.svg";
import { searchUsers } from "../../../api/users/users";
import { BookingEnum, StatusEnum } from "../../../shared/enums";
import { confirmBooking, updateBooking } from "../../../api/bookings/bookings";
import { getSlotByMember } from "../../../api/summaries/summaries";
import { getGuestByMemberId } from "../../../api/guests/guests";
import e from "express";

interface IEditCoPlayersPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: (bookingConfirmResponse: IBooking) => void;
  cognitoUser: CognitoUser | null;
  user: IUser | null;
  booking?: IBooking;
  coachesList: string[] | [];
  latestCoPlayers: IUser[] | [];
  coaches: ICoach[];
  courts: ICourt[];
  userData?: IUser;
  prices: IPrices[];
  permissions: IPermission[];
  isAdmin: boolean;
  guestDataFromAdmin?: IGuest[];
}
interface IAdditiveOption {
  value: "TRAINER" | "CO-PLAYER";
}
function EditCoPlayersPopup(props: IEditCoPlayersPopupProps) {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const [searchedUsers, setSearchedUsers] = useState<IUser[]>([]);

  useBeforeUnload(
    "Din bokning kommer att avbrytas om du lämnar sidan.",
    props.showPopup,
    () => handleClose
  );

  const { coachesList, latestCoPlayers, coaches, booking } = props;
  const [chosenCoPlayers, setChosenCoPlayers] = useState<IUser[]>([]);
  const [previousChosenCoPlayers, setPreviousChosenCoPlayers] = useState<
    IUser[]
  >([]);
  const [chosenCoach, setChosenCoach] = useState<string>("");
  const [availableCoPlayers, setAvailableCoPlayers] = useState<IUser[]>([]);
  const [guests, setGuests] = useState<string[]>([]);
  const [guestsData, setGuestsData] = useState<IGuest[]>([]);
  const [totalCoPlayers, setTotalCoPlayers] = useState<number>(0);
  const [currentAdditiveChoice, setCurrentAdditiveChoice] =
    useState<IAdditiveOption>({ value: "CO-PLAYER" });
  const [currentAdditiveText, setCurrentAdditiveText] =
    useState<string>("Välj medspelare");
  const [isDropIn, setIsDropIn] = useState<boolean>(false);

  const [coPlayerSlots, setCoPlayerSlots] = useState<{
    [key: string]: ISummaryItem;
  }>({});
  const [guestSlot, setGuestSlot] = useState<ISummaryItem | null>(null);

  const additiveOptions = [
    { label: "Medspelare", value: "CO-PLAYER", text: "Välj medspelare" },
    { label: "Tränare", value: "TRAINER", text: "Välj tränare" },
  ];

  const fetchUsersFromBooking = async () => {
    if (!booking) return;
    let guests: string[] = [];

    booking.guests.forEach((guest) => (guests = [...guests, guest.member_id]));
    setGuests(guests);
    props.guestDataFromAdmin &&
      setGuestsData(
        props.guestDataFromAdmin.filter((g) => guests.includes(g.member_id))
      );
    if (booking.co_players.length === 0) return;
    const usersToFetch = booking.co_players.map((player) => player.member_id);
    if (usersToFetch) {
      try {
        const response = await Promise.all(
          usersToFetch.map((user) => searchUsers(user, "user", true))
        );
        let users: IUser[] = [];
        response.forEach((user) => {
          users = [...users, ...user];
        });
        setChosenCoPlayers(users);
      } catch (error: any) {
        toast.error(error);
      }
    }
  };
  const handleCoPlayerSlot = async (
    datetime_start: string,
    member_id: string,
    court_id: string,
    court_type: ICourtType
  ) => {
    const isDropIn = booking?.booking_type === BookingEnum.DROP_IN;
    try {
      const response = await getSlotByMember(
        datetime_start,
        court_id,
        court_type,
        member_id,
        isDropIn
      );
      return response;
    } catch (error: any) {
      toast.error(error);
    }
  };
  const handleGuestSlot = async (
    datetime_start: string,
    court_id: string,
    court_type: ICourtType
  ) => {
    try {
      const response = await getSlotByMember(
        datetime_start,
        court_id,
        court_type
      );
      return response;
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (
      chosenCoPlayers.length > 0 &&
      previousChosenCoPlayers !== chosenCoPlayers
    ) {
      const promises = chosenCoPlayers.map((player) =>
        handleCoPlayerSlot(
          props.booking?.datetime_start || "",
          player.member_id,
          props.booking?.court_id || "",
          props.booking?.court_id as ICourtType
        ).then((response) => ({ [player.member_id]: response }))
      );
      Promise.all(promises).then((responses) => {
        const slots = responses.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        setCoPlayerSlots((prev) => ({ ...prev, ...slots }));
      });
      setPreviousChosenCoPlayers(chosenCoPlayers);
    }
  }, [chosenCoPlayers]);

  useEffect(() => {
    if (
      (guests.length > 0 && guests.length <= 1) ||
      (guestSlot === null && guests.length > 0)
    ) {
      handleGuestSlot(
        props.booking?.datetime_start || "",
        props.booking?.court_id || "",
        props.booking?.court_id as ICourtType
      ).then((response) => {
        setGuestSlot(response);
      });
    }
  }, [guests]);

  useEffect(() => {
    if (props.showPopup) {
      fetchUsersFromBooking();
      setAvailableCoPlayers(latestCoPlayers);
      if (props.booking && props.booking.coach_id.length > 0) {
        setChosenCoach(props.booking.coach_id[0]);
      }
      if (props.booking) {
        setIsDropIn(props.booking.booking_type === BookingEnum.DROP_IN);
      }
    }
  }, [props.showPopup, booking, latestCoPlayers]);

  useEffect(() => {
    setTotalCoPlayers(chosenCoPlayers.length + guests.length);
  }, [guests, chosenCoPlayers]);

  useEffect(() => {
    setAvailableCoPlayers(latestCoPlayers);
  }, [latestCoPlayers, props.showPopup]);

  const handleClose = () => {
    setSearchString("");
    setSearchedUsers([]);
    setChosenCoPlayers([]);
    setChosenCoach("");
    setCurrentAdditiveChoice({ value: "CO-PLAYER" });
    setGuests([]);
    props.onClose();
  };

  const handleConfirm = (bookingConfirmResponse: IBooking) => {
    setSearchString("");
    setSearchedUsers([]);
    setChosenCoPlayers([]);
    setCurrentAdditiveChoice({ value: "CO-PLAYER" });
    setGuests([]);
    props.onConfirm(bookingConfirmResponse);
    props.onClose();
  };
  const handleAdditiveChoiceToggle = (value: IAdditiveOption) => {
    setCurrentAdditiveChoice(value);
    const selectedOption = additiveOptions.find(
      (option) => option.value === value.value
    );
    if (selectedOption) {
      setCurrentAdditiveText(selectedOption.text);
    }
  };

  const handleUpdateBooking = async () => {
    if (props.booking === undefined || isUpdating) return;
    setIsUpdating(true);
    let removedCoPLayersList: string[] = [];
    let addedCoPlayersList: string[] = [];
    let removedCoachId: string | undefined =
      booking?.coach_id[0] !== chosenCoach ? booking?.coach_id[0] : "";
    let addedCoachId: string | undefined =
      booking?.coach_id[0] !== chosenCoach && chosenCoach !== ""
        ? chosenCoach
        : "";

    let addedGuestsList: string[] = [];
    let removedGuestsList: string[] = [];

    if (addedCoachId === undefined) {
      addedCoachId = "";
    }
    if (removedCoachId === undefined) {
      removedCoachId = "";
    }

    const removedCoPLayers = props.booking.co_players.filter(
      (player) =>
        !chosenCoPlayers.some(
          (chosenPlayer) => chosenPlayer.member_id === player.member_id
        )
    );
    removedCoPLayers.forEach((player) => {
      removedCoPLayersList.push(player.member_id);
    });

    const addedCoPlayers = chosenCoPlayers.filter(
      (player) =>
        !props.booking?.co_players.some(
          (chosenPlayer) => chosenPlayer.member_id === player.member_id
        )
    );
    addedCoPlayers.forEach((player) => {
      addedCoPlayersList.push(player.member_id);
    });

    const bookingGuestCount = props.booking.guests.filter(
      (guest) => guest.member_id === "guest"
    ).length;
    const currentGuestCount = guests.filter(
      (guest) => guest === "guest"
    ).length;

    if (currentGuestCount > bookingGuestCount) {
      const addedDefaultGuests = Array(
        currentGuestCount - bookingGuestCount
      ).fill("guest");
      addedGuestsList.push(...addedDefaultGuests);
    } else if (currentGuestCount < bookingGuestCount) {
      const removedDefaultGuests = Array(
        bookingGuestCount - currentGuestCount
      ).fill("guest");
      removedGuestsList.push(...removedDefaultGuests);
    }

    // Handle guests with unique member_ids (not "guest")
    const removedGuests = props.booking.guests.filter(
      (guest) =>
        guest.member_id !== "guest" && !guests.includes(guest.member_id)
    );
    removedGuests.forEach((guest) => {
      removedGuestsList.push(guest.member_id);
    });

    const addedGuests = guests.filter(
      (guest) =>
        guest !== "guest" &&
        !props.booking?.guests.some((g) => g.member_id === guest)
    );
    addedGuests.forEach((guest) => {
      addedGuestsList.push(guest);
    });
    let memberType = undefined;
    if (!props.isAdmin) {
      memberType = "user" as "admin" | "user";
    } else {
      memberType = "admin" as "admin" | "user";
    }
    const toastLoeader = toast.loading("Bekräftar ändringar...");

    try {
      const bookingConfirmResponse = await updateBooking(
        props.booking,
        removedCoPLayersList,
        addedCoPlayersList,
        removedGuestsList,
        addedGuestsList,
        memberType,
        isDropIn,
        removedCoachId,
        addedCoachId
      );
      if (bookingConfirmResponse && !("message" in bookingConfirmResponse)) {
        toast.dismiss(toastLoeader);
        toast.success("Ändringar bekräftade!");
        handleConfirm(bookingConfirmResponse);
      }
    } catch (error: any) {
      toast.dismiss(toastLoeader);
      toast.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const addCoach = (coach: string) => {
    setChosenCoach(coach);
    toast.success("Tränare vald");
  };
  const removeCoach = (coach: string) => () => {
    setChosenCoach("");
    toast.success("Tränare borttagen");
  };
  const addPlayerAsCoPlayer = (player: IUser, source: string) => {
    setSearchString("");
    setSearchedUsers([]);
    if (totalCoPlayers === 3) {
      toast.error("Du kan inte välja fler än 3 medspelare");
      return;
    }
    setChosenCoPlayers((prev) => [...prev, { ...player, source }]);

    toast.success(
      `Medspelare ${
        capitalizeWords(player.first_name) +
        " " +
        capitalizeWords(player.last_name)
      } tillagd`
    );
  };

  const addGuest = () => {
    setGuests((prev) => [...prev, "guest"]);
  };

  const removeCoPlayer = (player: any) => () => {
    setChosenCoPlayers((prev) =>
      prev.filter((p) => p.member_id !== player.member_id)
    );
    // if (player.source === "available") {
    //   setAvailableCoPlayers((prev) => [...prev, player]);
    // } else if (player.source === "filtered") {
    //   setFilteredOtherUsers((prev) => [...prev, player]);
    // }
    toast.success(
      `Medspelare ${
        capitalizeWords(player.first_name) +
        " " +
        capitalizeWords(player.last_name)
      } borttagen`
    );
  };

  const lastWarningTimeRef = useRef<number>(0);

  const showWarning = useCallback(() => {
    const now = Date.now();
    if (now - lastWarningTimeRef.current >= 5000) {
      lastWarningTimeRef.current = now;
      toast.warning("Inga användare hittades");
    }
  }, []);

  const fetchSearchUsers = async (search: string) => {
    const isAdmin = props.isAdmin;
    let requestor = "user" as "admin" | "user";
    if (isAdmin) {
      requestor = "admin" as "admin" | "user";
    }
    try {
      const response = await searchUsers(search, requestor, true);
      if (response.length === 0) {
        showWarning();
      }
      setSearchedUsers(response);
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (searchString.length > 0) {
      handleSearchUsers();
    } else {
      setSearchedUsers([]);
    }
  }, [searchString]);

  const handleSearchUsers = () => {
    fetchSearchUsers(searchString);
  };
  /** End search users  */
  const startDate = new Date(booking?.datetime_start || "");
  const { weekday, dateAndMonth, hour } = formatDateToSwedish(
    new Date(startDate)
  );
  const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);
  const formattedEndHour = endDate.toTimeString().substring(0, 5);
  const dateString = `${weekday} ${dateAndMonth}`;
  const courtName = props.courts.find(
    (c) => c.court_id === booking?.court_id
  )?.name;
  const courtType = props.courts.find(
    (c) => c.court_id === booking?.court_id
  )?.court_type;

  const timeCourtString = `${hour} - ${formattedEndHour} ${courtName}`;

  const isReady = props.booking !== undefined;
  const chosenCoachData = chosenCoach
    ? coaches.find((c) => c.coach_id === chosenCoach)
    : null;
  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={
        <span className="text-m">
          Din bokning{" "}
          {booking?.booking_type === BookingEnum.DROP_IN && "(Drop in)"}
        </span>
      }
      loading={!isReady}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form less-gap"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="general-popup-form-top">
            {isReady && (
              <button
                className="text-m"
                onClick={() => handleUpdateBooking()}
                disabled={isUpdating}
              >
                Uppdatera bokning
              </button>
            )}
          </div>
          <div className="general-popup-form-group mb-20">
            <div className="general-popup-form-field">
              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Datum</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{dateString}</span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
              <div className="general-popup-flex-between">
                <span className="text-m primary-text ">Tid + Bana</span>
                <div className="text-m primary-text text-svg-wrapper">
                  <span>{timeCourtString}</span>
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>

              <div className="general-popup-flex-between">
                <span className="text-m primary-text">Huvudbokare</span>
                <div className="text-m primary-text text-svg-wrapper">
                  {props.userData && (
                    <span>
                      {capitalizeWords(props.userData.first_name || "")}{" "}
                      {capitalizeWords(props.userData.last_name || "")} (
                      {props.userData.member_id})
                    </span>
                  )}
                  {props.user && (
                    <span>
                      {capitalizeWords(props.user.first_name || "")}{" "}
                      {capitalizeWords(props.user.last_name || "")} (
                      {props.user.member_id})
                    </span>
                  )}
                  <img src={greenCheck} alt="green check"></img>
                </div>
              </div>
              <div className="general-popup-flex-between align-top">
                <span className="text-m primary-text">Medspelare</span>
                <span className="text-m primary-text text-svg-wrapper">
                  <div className="chosen-players">
                    {chosenCoPlayers.map((player, index) => {
                      const coPlayerPermission = props.permissions.find(
                        (permission) =>
                          permission.member_type === player.member_type
                      );

                      let coPlayerPrice = 0;
                      const coPlayerSlot = coPlayerSlots[player.member_id];

                      const coPlayerPriceString =
                        coPlayerSlot !== undefined ? coPlayerSlot.price : 0;
                      coPlayerPrice = Number(coPlayerPriceString);
                      let isForetag = false;
                      if (
                        (coPlayerPermission &&
                          coPlayerPermission.member_type === "Företag") ||
                        (coPlayerPermission &&
                          coPlayerPermission.member_type === "Företag B")
                      ) {
                        isForetag = true;
                      }
                      return (
                        <div key={index} className="chosen-player">
                          {capitalizeWords(player.first_name)}{" "}
                          {capitalizeWords(player.last_name)} (
                          {player.member_id}) {Number(coPlayerPrice)} kr
                          {isForetag && <div>(pris kan tillkomma)</div>}
                          <img
                            onClick={removeCoPlayer(player)}
                            src={singleCross}
                            alt="cross"
                            className="cross-img pointer"
                          ></img>
                        </div>
                      );
                    })}
                    {guests.map((guest, index) => {
                      let price = "";
                      price = guestSlot?.price || "";

                      let isCheckedInGuest;
                      if (guest !== "guest") {
                        isCheckedInGuest = guestsData.find(
                          (g) => g.member_id === guest
                        );
                        price =
                          props.booking?.final_price
                            .find((e) => e.event.includes(guest))
                            ?.price.toString() || "";
                      }

                      return (
                        <div className="chosen-player" key={index}>
                          {guest !== "guest"
                            ? capitalizeWords(
                                isCheckedInGuest?.first_name +
                                  " " +
                                  isCheckedInGuest?.last_name
                              ) +
                              " (" +
                              isCheckedInGuest?.member_id +
                              ")"
                            : "Gäst"}{" "}
                          ({price} kr)
                          <img
                            onClick={() =>
                              setGuests((prev) =>
                                prev.filter((g, i) => i !== index)
                              )
                            }
                            src={singleCross}
                            alt="cross"
                            className="cross-img pointer"
                          ></img>
                        </div>
                      );
                    })}
                  </div>
                </span>
              </div>
              {(coachesList.length > 0 || chosenCoach !== "") && (
                <div className="general-popup-flex-between">
                  <span className="text-m primary-text">Tränare</span>
                  <span className="text-m primary-text text-svg-wrapper">
                    {chosenCoachData && (
                      <div className="added-coach-remove-wrapper">
                        <span>
                          {chosenCoachData.first_name}{" "}
                          {chosenCoachData.last_name} (
                          {chosenCoachData.coach_id !== "5513"
                            ? chosenCoachData.coach_price +
                              50 * chosenCoPlayers.length
                            : chosenCoachData.coach_price}{" "}
                          kr)
                        </span>
                        <img
                          onClick={removeCoach(chosenCoach)}
                          src={singleCross}
                          alt="cross"
                          className="cross-img pointer"
                        ></img>
                      </div>
                    )}
                  </span>
                </div>
              )}
              {props.isAdmin && (
                <div className="general-popup-flex-between">
                  <span className="text-m secondary-text">Drop in</span>
                  <label className="toggle text-m">
                    <input
                      type="checkbox"
                      name="closed"
                      checked={isDropIn}
                      onChange={(e) => setIsDropIn(!isDropIn)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="general-popup-lila-border-group">
            <div className="co-player-trainer-wrapper">
              <div className="user-booking-court-type">
                <div className="user-booking-text-toggle-wrapper">
                  <div className="user-bookings-toggle-text text-l">
                    {currentAdditiveText}
                  </div>
                  <div className="users-bookings-toggle-buttons">
                    {additiveOptions.map((option, index) => {
                      const trainersAvailale = coachesList.length > 0;

                      if (!trainersAvailale || guests.length > 0) return null;

                      return (
                        <div
                          key={index}
                          className="user-booking-additive-toggle-wrapper"
                        >
                          <button
                            className={`toggle-button text-m ${
                              currentAdditiveChoice.value === option.value
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleAdditiveChoiceToggle(
                                option as IAdditiveOption
                              )
                            }
                          >
                            {option.label}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {currentAdditiveChoice.value === "TRAINER" &&
                  coachesList.length > 0 && (
                    <div className="coach-choices-wrapper text-m">
                      <div>
                        <span>Tillgängliga tränare</span>
                      </div>
                      {coachesList.map((coach, index) => {
                        const coachWidthData = coaches.find(
                          (c) => c.coach_id === coach
                        );
                        if (!coachWidthData) return;
                        return (
                          <div
                            key={index}
                            className="coach-wrapper pointer"
                            onClick={() => addCoach(coach)}
                          >
                            <div className="coach-name">
                              {capitalizeWords(coachWidthData.first_name)}{" "}
                              {capitalizeWords(coachWidthData.last_name)}
                            </div>
                            <div className="coach-price-id-wrapper">
                              <div className="coach-id">({coach})</div>
                              <div className="coach-price">
                                {coachWidthData.coach_price} kr
                              </div>
                            </div>
                            <a className="choose-coach"> Välj Coach</a>
                          </div>
                        );
                      })}
                    </div>
                  )}
                {currentAdditiveChoice.value === "CO-PLAYER" && (
                  <div className="co-player-choices-wrapper text-m">
                    <div>
                      <span>Senaste medspelare</span>
                    </div>
                    {availableCoPlayers.length > 0 ? (
                      availableCoPlayers.map((player, index) => {
                        if (
                          chosenCoPlayers.some(
                            (coPlayer) =>
                              coPlayer.member_id === player.member_id
                          ) ||
                          player.member_id === props.user?.member_id
                        ) {
                          return null;
                        }
                        if (player.member_type === "Före detta medlem")
                          return null;
                        if (index > 4) return null;
                        const isFrozen = player.frozen;
                        return (
                          <div
                            key={index}
                            className="player-wrapper pointer"
                            onClick={() =>
                              addPlayerAsCoPlayer(player, "available")
                            }
                          >
                            <div
                              className={`player-name ${
                                isFrozen ? "frozen" : ""
                              }`}
                            >
                              {capitalizeWords(player.first_name)}{" "}
                              {capitalizeWords(player.last_name)}
                            </div>
                            <div className="player-id">
                              {" "}
                              {player.member_id}{" "}
                            </div>
                            <a className="choose-player"> Välj medspelare</a>
                          </div>
                        );
                      })
                    ) : (
                      <div className="no-latest-players secondary-text">
                        Du har inte spelat med någon tidigare
                      </div>
                    )}
                    <div>
                      <span>Övriga medspelare</span>
                    </div>
                    <div className="search-input-btn-wrapper">
                      <div className="search-input-wrapper">
                        <input
                          className="users-search-input text-m"
                          value={searchString}
                          onChange={(e) => setSearchString(e.target.value)}
                          placeholder="Sök på namn & medlemsnummer"
                        ></input>
                        <i className="fa-regular fa-magnifying-glass search-icon"></i>
                      </div>
                    </div>
                    {searchedUsers.length > 0 &&
                      searchedUsers
                        .filter(
                          (user) =>
                            !availableCoPlayers.some(
                              (coPlayer) =>
                                coPlayer.member_id === user.member_id
                            ) &&
                            !chosenCoPlayers.some(
                              (coPlayer) =>
                                coPlayer.member_id === user.member_id
                            ) &&
                            user.member_id !== props.user?.member_id
                        )
                        .map((player, index) => {
                          // if (player.member_type === "Före detta medlem")
                          //   return null;
                          if (index > 4) return null;
                          const isFrozen = player.frozen;
                          return (
                            <div key={index} className="player-wrapper pointer">
                              <div
                                className={`player-name ${
                                  isFrozen ? "frozen" : ""
                                }`}
                              >
                                <span>
                                  {capitalizeWords(player.first_name)}{" "}
                                  {capitalizeWords(player.last_name)}
                                </span>
                              </div>
                              <div className="player-id">
                                {player.member_id}
                              </div>
                              <a
                                className="choose-player"
                                onClick={() =>
                                  addPlayerAsCoPlayer(player, "filtered")
                                }
                              >
                                Välj medspelare
                              </a>
                            </div>
                          );
                        })}

                    <div className="add-guest-wrapper flex-row">
                      <button
                        onClick={() => addGuest()}
                        disabled={totalCoPlayers === 3 || chosenCoach !== ""}
                      >
                        Lägg till gäst
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default EditCoPlayersPopup;
