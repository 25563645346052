import { useEffect, useState } from "react";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
import "./CoachHandleTimes.scss";
import {
  IBooking,
  ICoach,
  ICoachAvailability,
  ICoachBooking,
  ICoachIntervalItem,
  ICourt,
  ICourtType,
  IntervalItem,
} from "../../types/api";
import { getCoachByID } from "../../api/coaches/coaches";
import { CognitoUser } from "../../types/cognito";
import { Auth } from "aws-amplify";
import CoachHandleAvailabilityPopup from "../../containers/Coaches/CoachHandleAvailabilityPopup/CoachHandleAvailabilityPopup";
import { combinedForCoachQuery } from "../../api/combinedQueries/combinedForCoachQuery";
import {
  capitalizeWords,
  formatDateToSwedish,
  getMondayOfWeek,
  isDateInCurrentWeek,
} from "../../shared/utility";
import WeekSelector from "../../hoc/DatePicker/Weekpicker";
import CoachShowBookedInfoPopup from "../../containers/Coaches/ CoachShowBookedInfoPopup/ CoachShowBookedInfoPopup";
import { formatToIsoDate } from "../../shared/dateUtils";
import HandleConfirmationPopup from "../../containers/HandleConfirmation/HandleConfirmationPopup";
import { CourtTypeEnum, Weekday } from "../../shared/enums";
import { IFacility } from "../../types/facility";
import Spinner from "../../components/UI/Spinner/Spinner";

const getWeekdayIndex = (weekday: string): number => {
  const weekdayMap: Record<string, Weekday> = {
    monday: Weekday.Monday,
    tuesday: Weekday.Tuesday,
    wednesday: Weekday.Wednesday,
    thursday: Weekday.Thursday,
    friday: Weekday.Friday,
    saturday: Weekday.Saturday,
    sunday: Weekday.Sunday,
  };
  return weekdayMap[weekday.toLowerCase()] ?? -1; // Return -1 if not found
};

interface CoachHandleTimesProps {}

function CoachHandleTimes(props: CoachHandleTimesProps) {
  const [showBookedInfoPopup, setShowBookedInfoPopup] = useState(false);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [coachAvailability, setCoachAvailability] =
    useState<ICoachAvailability[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [
    showCoachHandleAvailabilityPopup,
    setShowCoachHandleAvailabilityPopup,
  ] = useState(false);
  const [
    showHandleDeleteAvailabilityPopup,
    setShowHandleDeleteAvailabilityPopup,
  ] = useState(false);

  const [showHandleAddAvailabilityPopup, setShowHandleAddAvailabilityPopup] =
    useState(false);
  const [facilityOpeningHours, setFacilityOpeningHours] = useState<IFacility>();

  const [coachData, setCoachData] = useState<ICoach>();
  const [bookingToShow, setBookingToShow] = useState<ICoachBooking>();
  const [filterDate, setFilterDate] = useState(() => {
    const storedDate = localStorage.getItem("filterDate");
    return storedDate ? new Date(storedDate) : new Date();
  });
  const [slotToBeRemoved, setSlotToBeRemoved] = useState<any>();
  const [sendSlotToBeRemoved, setSendSlotToBeRemoved] = useState<any>();
  const [slotToBeAdded, setSlotToBeAdded] = useState<any>();
  const [sendSlotToBeAdded, setSendSlotToBeAdded] = useState<any>();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        setUser(userData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("filterDate", formatToIsoDate(filterDate));
  }, [filterDate]);

  useEffect(() => {
    if (slotToBeRemoved) {
      setShowHandleDeleteAvailabilityPopup(true);
    }
  }, [slotToBeRemoved]);
  useEffect(() => {
    if (slotToBeAdded) {
      setShowHandleAddAvailabilityPopup(true);
    }
  }, [slotToBeAdded]);

  const fetchChosenItems = async ({
    fetchCoachData = false,
    fetchCoachAvailabilityWeekly = false,
    fetchCourts = false,
    fetchFacility = false,
    court_type = "INDOOR" as ICourtType,
  }) => {
    if (!user) return;
    setIsLoading(true);
    try {
      const response = await combinedForCoachQuery({
        member_id: user.attributes["custom:member_id"],
        date: formatToIsoDate(filterDate),
        court_type: court_type,
        fetchCoachData,
        fetchCoachAvailabilityWeekly,
        fetchCourts,
        fetchFacility,
      });
      if (response.courts && fetchCourts && !("message" in response.courts)) {
        setCourts(response.courts);
      }
      if (
        response.facility &&
        fetchFacility &&
        !("message" in response.facility)
      ) {
        setFacilityOpeningHours(response.facility);
      }
      if (
        response.coachData &&
        fetchCoachData &&
        !("message" in response.coachData)
      ) {
        setCoachData(response.coachData);
      }
      if (
        response.coachAvailabilityWeekly &&
        fetchCoachAvailabilityWeekly &&
        !("message" in response.coachAvailabilityWeekly)
      ) {
        setCoachAvailability(response.coachAvailabilityWeekly);
      }
    } catch (error) {
      console.error("Error fetching coach data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChosenItems({
      fetchCoachData: true,
      fetchCoachAvailabilityWeekly: true,
      fetchCourts: true,
      fetchFacility: true,
    });
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
    }
  }, [filterDate]);
  useEffect(() => {
    if (sendSlotToBeRemoved) {
      setShowCoachHandleAvailabilityPopup(true);
    }
  }, [sendSlotToBeRemoved]);
  useEffect(() => {
    if (sendSlotToBeAdded) {
      setShowCoachHandleAvailabilityPopup(true);
    }
  }, [sendSlotToBeAdded]);

  const handleShowBookingInfoPopup = (booking: ICoachBooking) => {
    setBookingToShow(booking);
    setShowBookedInfoPopup(true);
  };

  const generateWeeklySlots = () => {
    if (!facilityOpeningHours || !coachAvailability) return null;

    const startDate = new Date(filterDate);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Include the full week

    return (
      <tr>
        {Array.from({ length: 7 }).map((_, index) => {
          const date = new Date(startDate); // Clone startDate
          date.setDate(startDate.getDate() + index); // Increment by index
          const isoDate = formatToIsoDate(date);

          let openingTime: string | null = null;
          let closingTime: string | null = null;
          const specialDay = facilityOpeningHours.special_days?.find(
            (day) => day.date === isoDate.split("T")[0]
          );

          // **1. Check if the date falls within a seasonal period**
          const seasonalPeriod = facilityOpeningHours.seasonal_hours?.find(
            (period) =>
              isoDate.split("T")[0] >= period.start_date &&
              isoDate.split("T")[0] <= period.end_date
          );
          if (specialDay) {
            openingTime = specialDay.opening_hours;
            closingTime = specialDay.closing_hours;
          } else if (seasonalPeriod) {
            // Match the weekday from `weekday_hours`
            const weekdayString = date
              .toLocaleString("en-US", { weekday: "long" })
              .toLowerCase();
            const dayHours = seasonalPeriod.weekday_hours.find(
              (d) => d.weekday.toLowerCase() === weekdayString
            );

            if (dayHours) {
              openingTime = dayHours.opening_hours;
              closingTime = dayHours.closing_hours;
            }
          }

          // **2. Fallback to default hours if no seasonal period applies**
          if (!openingTime || !closingTime) {
            const weekdayString = date
              .toLocaleString("en-US", { weekday: "long" })
              .toLowerCase();
            const dayHours = facilityOpeningHours.default_hours.find(
              (d) => d.weekday.toLowerCase() === weekdayString
            );

            if (dayHours) {
              openingTime = dayHours.opening_hours;
              closingTime = dayHours.closing_hours;
            }
          }

          // Skip rendering if no valid hours are found
          if (!openingTime || !closingTime) return <td key={index}></td>;

          // **3. Generate time slots for the day**
          const coachDay = coachAvailability.find(
            (day) => day.date === isoDate.split("T")[0]
          );
          const coachIntervals = coachDay ? coachDay.intervals : [];
          let slots: JSX.Element[] = [];
          let currentTime = new Date(`1970-01-01T${openingTime}`);
          const endTime = new Date(`1970-01-01T${closingTime}`);

          while (currentTime < endTime) {
            let nextTime = new Date(currentTime.getTime() + 30 * 60000);
            let slotTime = `${currentTime
              .toTimeString()
              .slice(0, 5)} - ${nextTime.toTimeString().slice(0, 5)}`;

            const isWithinCoachAvailability = coachIntervals.some(
              (interval) => {
                const intervalStart = new Date(
                  `1970-01-01T${interval.start_time}`
                );
                const intervalEnd = new Date(`1970-01-01T${interval.end_time}`);
                return (
                  currentTime >= intervalStart && currentTime < intervalEnd
                );
              }
            );

            const bookedInterval = coachIntervals.find((interval) => {
              const intervalStart = new Date(
                `1970-01-01T${interval.start_time}`
              );
              const intervalEnd = new Date(`1970-01-01T${interval.end_time}`);
              return (
                interval.booking &&
                currentTime >= intervalStart &&
                currentTime < intervalEnd
              );
            });

            if (bookedInterval) {
              slotTime = `${bookedInterval.start_time.slice(
                0,
                5
              )} - ${bookedInterval.end_time.slice(0, 5)}`;
              nextTime = new Date(`1970-01-01T${bookedInterval.end_time}`); // Jump to end of booking
            }

            slots.push(
              <div
                key={slotTime}
                className={`time-item-wrapper text-m pointer ${
                  bookedInterval
                    ? "slot-booked"
                    : isWithinCoachAvailability
                    ? "white"
                    : "not-available"
                }`}
                onClick={() =>
                  bookedInterval
                    ? handleShowBookingInfoPopup(
                        bookedInterval.booking as IBooking
                      )
                    : isWithinCoachAvailability
                    ? setSlotToBeRemoved({ slot: slotTime, date: isoDate })
                    : setSlotToBeAdded({ slot: slotTime, date: isoDate })
                }
              >
                <div className="time-content-wrapper">
                  <div>{slotTime}</div>
                  {bookedInterval && (
                    <div>
                      {capitalizeWords(
                        bookedInterval.booking?.member_name || ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            );

            currentTime = nextTime;
          }

          return <td key={index}>{slots}</td>;
        })}
      </tr>
    );
  };

  if (!user || !coachData || !coachAvailability) return null;

  return (
    <div className="coach-times-page-container">
      <div className="coach-times-layout">
        <div className="coach-times-declarations">
          <h1 className="text-l primary-text">
            Tränare - {coachData.first_name}
          </h1>
          <div className="declarations-wrapper text-m">
            <span className="declaration"> Bokade (Blå)</span>
            <span className="declaration"> Tillgängliga (Vit)</span>
            <span className="declaration"> Icke tillgängliga (Grå)</span>
          </div>
        </div>

        <div className="coach-times-action-wrapper">
          <div className="coach-times-weekday-selector-wrapper">
            <WeekSelector
              value={filterDate}
              onChange={setFilterDate}
              includeWeekdays={false}
              textM
              onWeekChange={(date) => setFilterDate(date)}
            />
            {!isDateInCurrentWeek(filterDate) && (
              <a
                className="text-m"
                onClick={() => {
                  const today = new Date();
                  const monday = getMondayOfWeek(today);
                  setFilterDate(monday);
                }}
              >
                Till denna vecka
              </a>
            )}
          </div>
          <div className="coach-availability-action-price-wrapper">
            <button
              className="text-m"
              onClick={() => {
                fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
                setShowCoachHandleAvailabilityPopup(true);
              }}
            >
              Ändra tillgänglighet
            </button>
            <div className="coach-times-prices-wrapper text-m">
              Pris: {coachData.coach_price} (kr/h)
            </div>
          </div>
        </div>
        <div className="coach-table-overflow-wrapper">
          <table className="coach-table">
            <thead>
              <tr>
                {coachAvailability.map((day, index) => {
                  const date = new Date(day.date);
                  const { weekday } = formatDateToSwedish(date);

                  return (
                    <th key={index} className="text-s secondary-text">
                      {weekday} - {formatToIsoDate(date).slice(8, 10)}/
                      {formatToIsoDate(date).slice(5, 7)}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {isLoading ? (
              <Spinner />
            ) : (
              <tbody className="coach-table-body">
                {generateWeeklySlots()}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <CoachShowBookedInfoPopup
        showPopup={showBookedInfoPopup}
        onClose={() => setShowBookedInfoPopup(false)}
        onConfirm={() => {
          fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
          setShowBookedInfoPopup(false);
        }}
        booking={bookingToShow}
        courts={courts}
      />
      <CoachHandleAvailabilityPopup
        showPopup={showCoachHandleAvailabilityPopup}
        onClose={() => setShowCoachHandleAvailabilityPopup(false)}
        onConfirm={() => {
          setSlotToBeRemoved(undefined);
          setSlotToBeAdded(undefined);
          setSendSlotToBeAdded(undefined);
          setSendSlotToBeRemoved(undefined);
          fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
        }}
        coach={coachData}
        coachAvailability={coachAvailability}
        filterDate={filterDate}
        slotToBeRemoved={sendSlotToBeRemoved}
        slotToBeAdded={sendSlotToBeAdded}
      />
      <HandleConfirmationPopup
        showPopup={showHandleDeleteAvailabilityPopup}
        onClose={() => setShowHandleDeleteAvailabilityPopup(false)}
        onConfirm={() => {
          setSendSlotToBeRemoved(slotToBeRemoved);
          setShowHandleDeleteAvailabilityPopup(false);
        }}
        confirmText="Ta bort"
        denyText="Avbryt"
        questionText={`Är du säker på att du vill ta bort ${slotToBeRemoved?.slot}?`}
      />
      <HandleConfirmationPopup
        showPopup={showHandleAddAvailabilityPopup}
        onClose={() => setShowHandleAddAvailabilityPopup(false)}
        onConfirm={() => {
          setSendSlotToBeAdded(slotToBeAdded);
          setShowHandleAddAvailabilityPopup(false);
        }}
        confirmText="Lägg till"
        denyText="Avbryt"
        questionText={`Är du säker på att du vill lägga till ${slotToBeAdded?.slot}?`}
      />
    </div>
  );
}
export default CoachHandleTimes;

// import { useEffect, useState } from "react";
// import WeekDaySelector from "../../hoc/DatePicker/Datepicker";
// import "./CoachHandleTimes.scss";
// import {
//   IBooking,
//   ICoach,
//   ICoachAvailability,
//   ICoachBooking,
//   ICoachIntervalItem,
//   ICourt,
//   ICourtType,
//   IntervalItem,
// } from "../../types/api";
// import { getCoachByID } from "../../api/coaches/coaches";
// import { CognitoUser } from "../../types/cognito";
// import { Auth } from "aws-amplify";
// import CoachHandleAvailabilityPopup from "../../containers/Coaches/CoachHandleAvailabilityPopup/CoachHandleAvailabilityPopup";
// import { combinedForCoachQuery } from "../../api/combinedQueries/combinedForCoachQuery";
// import { capitalizeWords, formatDateToSwedish } from "../../shared/utility";
// import WeekSelector from "../../hoc/DatePicker/Weekpicker";
// import CoachShowBookedInfoPopup from "../../containers/Coaches/ CoachShowBookedInfoPopup/ CoachShowBookedInfoPopup";
// import { formatToIsoDate } from "../../shared/dateUtils";
// import HandleConfirmationPopup from "../../containers/HandleConfirmation/HandleConfirmationPopup";
// import { CourtTypeEnum } from "../../shared/enums";
// import { IFacility } from "../../types/facility";

// interface CoachHandleTimesProps {}

// function CoachHandleTimes(props: CoachHandleTimesProps) {
//   const [showBookedInfoPopup, setShowBookedInfoPopup] = useState(false);
//   const [user, setUser] = useState<CognitoUser | null>(null);
//   const [courts, setCourts] = useState<ICourt[]>([]);
//   const [coachAvailability, setCoachAvailability] =
//     useState<ICoachAvailability[]>();
//   const [
//     showCoachHandleAvailabilityPopup,
//     setShowCoachHandleAvailabilityPopup,
//   ] = useState(false);
//   const [
//     showHandleDeleteAvailabilityPopup,
//     setShowHandleDeleteAvailabilityPopup,
//   ] = useState(false);
//   const [facilityOpeningHours, setFacilityOpeningHours] = useState<IFacility>();

//   const [coachData, setCoachData] = useState<ICoach>();
//   const [bookingToShow, setBookingToShow] = useState<ICoachBooking>();
//   const [filterDate, setFilterDate] = useState(() => {
//     const storedDate = localStorage.getItem("filterDate");
//     return storedDate ? new Date(storedDate) : new Date();
//   });
//   const [slotToBeRemoved, setSlotToBeRemoved] = useState<any>();
//   const [sendSlotToBeRemoved, setSendSlotToBeRemoved] = useState<any>();
//   useEffect(() => {
//     Auth.currentAuthenticatedUser()
//       .then((userData) => {
//         setUser(userData);
//       })
//       .catch((error) => {
//         console.error("Error fetching user data:", error);
//       });
//   }, []);

//   useEffect(() => {
//     localStorage.setItem("filterDate", formatToIsoDate(filterDate));
//   }, [filterDate]);
//   useEffect(() => {
//     if (slotToBeRemoved) {
//       setShowHandleDeleteAvailabilityPopup(true);
//     }
//   }, [slotToBeRemoved]);
//   const fetchChosenItems = async ({
//     fetchCoachData = false,
//     fetchCoachAvailabilityWeekly = false,
//     fetchCourts = false,
//     fetchFacility = false,
//     court_type = "INDOOR" as ICourtType,
//   }) => {
//     if (!user) return;

//     try {
//       const response = await combinedForCoachQuery({
//         member_id: user.attributes["custom:member_id"],
//         date: formatToIsoDate(filterDate),
//         court_type: court_type,
//         fetchCoachData,
//         fetchCoachAvailabilityWeekly,
//         fetchCourts,
//         fetchFacility,
//       });
//       if (response.courts && fetchCourts && !("message" in response.courts)) {
//         setCourts(response.courts);
//       }
//       if (
//         response.facility &&
//         fetchFacility &&
//         !("message" in response.facility)
//       ) {
//         setFacilityOpeningHours(response.facility);
//       }
//       if (
//         response.coachData &&
//         fetchCoachData &&
//         !("message" in response.coachData)
//       ) {
//         setCoachData(response.coachData);
//       }
//       if (
//         response.coachAvailabilityWeekly &&
//         fetchCoachAvailabilityWeekly &&
//         !("message" in response.coachAvailabilityWeekly)
//       ) {
//         setCoachAvailability(response.coachAvailabilityWeekly);
//       }
//     } catch (error) {
//       console.error("Error fetching coach data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchChosenItems({
//       fetchCoachData: true,
//       fetchCoachAvailabilityWeekly: true,
//       fetchCourts: true,
//       fetchFacility: true,
//     });
//   }, [user]);

//   useEffect(() => {
//     if (user) {
//       fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
//     }
//   }, [filterDate]);
//   useEffect(() => {
//     if (sendSlotToBeRemoved) {
//       setShowCoachHandleAvailabilityPopup(true);
//     }
//   }, [sendSlotToBeRemoved]);

//   const handleShowBookingInfoPopup = (booking: ICoachBooking) => {
//     setBookingToShow(booking);
//     setShowBookedInfoPopup(true);
//   };

//   const generateSlotsForInterval = (
//     interval: ICoachIntervalItem,
//     date: string
//   ) => {
//     const start = interval.start_time;
//     const end = interval.end_time;
//     const slots: string[] = [];

//     // Helper function to add 30-minute intervals
//     const add30MinuteSlots = (startTime: string, endTime: string) => {
//       let current = new Date(`1970-01-01T${startTime}`);
//       const end = new Date(`1970-01-01T${endTime}`);

//       while (current < end) {
//         const next = new Date(current.getTime() + 30 * 60000); // Add 30 minutes
//         slots.push(
//           `${current.toTimeString().slice(0, 5)} - ${next
//             .toTimeString()
//             .slice(0, 5)}`
//         );
//         current = next;
//       }
//     };

//     if (interval.booking) {
//       // If there's a booking, add the entire interval as one slot
//       slots.push(`${start.slice(0, 5)} - ${end.slice(0, 5)}`);
//       const booker = capitalizeWords(interval.booking?.member_name || "");

//       return (
//         <tr>
//           <td className="booked">
//             {slots.map((slot, index) => (
//               <div
//                 key={index}
//                 className="time-item-wrapper slot-booked pointer"
//                 onClick={() =>
//                   handleShowBookingInfoPopup(interval.booking as ICoachBooking)
//                 }
//               >
//                 <div className="time-content-wrapper">
//                   <div>{slot}</div>
//                   <div>{booker}</div>
//                 </div>
//               </div>
//             ))}
//           </td>
//         </tr>
//       );
//     } else {
//       // If there's no booking, generate 30-minute slots
//       add30MinuteSlots(start, end);

//       return (
//         <tr>
//           <td>
//             {slots.map((slot, index) => {
//               return (
//                 <div
//                   key={index}
//                   className="time-item-wrapper pointer"
//                   onClick={() => setSlotToBeRemoved({ slot, date })}
//                 >
//                   <div className="time-content-wrapper">
//                     <div>{slot}</div>
//                   </div>
//                 </div>
//               );
//             })}
//           </td>
//         </tr>
//       );
//     }
//   };

//   if (!user || !coachData || !coachAvailability) return null;

//   return (
//     <div className="coach-times-page-container">
//       <div className="coach-times-layout">
//         <div className="coach-times-declarations">
//           <h1 className="text-l primary-text">
//             Tränare - {coachData.first_name}
//           </h1>
//           <div className="declarations-wrapper text-m">
//             <span className="declaration"> Bokade (Blå)</span>
//             <span className="declaration"> Tillgängliga (Vit)</span>
//           </div>
//         </div>

//         <div className="coach-times-action-wrapper">
//           <div className="coach-times-weekday-selector-wrapper">
//             <WeekSelector
//               value={filterDate}
//               onChange={setFilterDate}
//               includeWeekdays={false}
//               textM
//             />
//             {filterDate.getDate() !== new Date().getDate() && (
//               <a className="text-m" onClick={() => setFilterDate(new Date())}>
//                 Till idag
//               </a>
//             )}
//           </div>
//           <div className="coach-availability-action-price-wrapper">
//             <button
//               className="text-m"
//               onClick={() => {
//                 fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
//                 setShowCoachHandleAvailabilityPopup(true);
//               }}
//             >
//               Ändra tillgänglighet
//             </button>
//             <div className="coach-times-prices-wrapper text-m">
//               Pris: {coachData.coach_price} (kr/h)
//             </div>
//           </div>
//         </div>
//         <div className="coach-table-overflow-wrapper">
//           <table className="coach-table">
//             <thead>
//               <tr>
//                 {coachAvailability.map((day, index) => {
//                   const date = new Date(day.date);
//                   const { weekday } = formatDateToSwedish(date);

//                   return (
//                     <th key={index} className="text-s secondary-text">
//                       {weekday} - {formatToIsoDate(date).slice(8, 10)}/
//                       {formatToIsoDate(date).slice(5, 7)}
//                     </th>
//                   );
//                 })}
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 {coachAvailability.map((day, index) => {
//                   return (
//                     <td key={index}>
//                       {day.intervals.map(
//                         (interval: ICoachIntervalItem, index) => {
//                           return (
//                             <table
//                               className="nested-coach-times-table text-s secondary-text"
//                               key={index}
//                             >
//                               <tbody>
//                                 {generateSlotsForInterval(interval, day.date)}
//                               </tbody>
//                             </table>
//                           );
//                         }
//                       )}
//                     </td>
//                   );
//                 })}
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//       <CoachShowBookedInfoPopup
//         showPopup={showBookedInfoPopup}
//         onClose={() => setShowBookedInfoPopup(false)}
//         onConfirm={() => {
//           fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
//           setShowBookedInfoPopup(false);
//         }}
//         booking={bookingToShow}
//         courts={courts}
//       />
//       <CoachHandleAvailabilityPopup
//         showPopup={showCoachHandleAvailabilityPopup}
//         onClose={() => setShowCoachHandleAvailabilityPopup(false)}
//         onConfirm={() => {
//           setSlotToBeRemoved(undefined);
//           setSendSlotToBeRemoved(undefined);
//           fetchChosenItems({ fetchCoachAvailabilityWeekly: true });
//         }}
//         coach={coachData}
//         coachAvailability={coachAvailability}
//         filterDate={filterDate}
//         slotToBeRemoved={sendSlotToBeRemoved}
//       />
//       <HandleConfirmationPopup
//         showPopup={showHandleDeleteAvailabilityPopup}
//         onClose={() => setShowHandleDeleteAvailabilityPopup(false)}
//         onConfirm={() => {
//           setSendSlotToBeRemoved(slotToBeRemoved);
//           setShowHandleDeleteAvailabilityPopup(false);
//         }}
//         confirmText="Ta bort"
//         denyText="Avbryt"
//         questionText={`Är du säker på att du vill ta bort ${slotToBeRemoved?.slot}?`}
//       />
//     </div>
//   );
// }
// export default CoachHandleTimes;
