import "./EditGuestPopup.scss";
import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IGuest, IUser } from "../../../types/api";
import { toast } from "react-toastify";
import { deleteUser, getUserByMemberId } from "../../../api/users/users";
import { capitalizeWords } from "../../../shared/utility";
import HandleConfirmationPopup from "../../HandleConfirmation/HandleConfirmationPopup";
import { TitleEnum } from "../../../shared/enums";
import {
  deleteGuest,
  getGuestByMemberId,
  updateGuest,
} from "../../../api/guests/guests";
import { phoneRegex, socialSecurityRegex } from "../../../shared/regex";
import ChooseUserPopup from "../../Admin/ChooseUserPopup/ChooseUserPopup";
import singleCross from "../../../assets/images/single-corss.svg";

interface IEditGuestPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  guestId: string;
}

const initialGuestData: IGuest = {
  member_id: "",
  social_security_number: "",
  first_name: "",
  last_name: "",
  gender: "",
  email: "",
  adress: "",
  postal_code: "",
  phone_number: "",
  company: "",
  comment: "",
  family_member: "",
  mass_email: "",
  times_played: 0,
  times_played_allowed: 0,
  family_member_id: "",
};

function EditGuestPopup(props: IEditGuestPopupProps) {
  const { guestId, onClose, onConfirm, showPopup } = props;
  const [hasGuestDataChanged, setHasGuestDataChanged] =
    useState<boolean>(false);
  const [showHandleDeleteGuestPopup, setShowHandleDeleteGuestPopup] =
    useState<boolean>(false);
  const [guestData, setGuestData] = useState(initialGuestData);
  const [originalData, setOriginalData] = useState<IGuest>(initialGuestData);
  const [showSearchUserPopup, setShowSearchUserPopup] =
    useState<boolean>(false);
  const [isSelectingFamilyMember, setIsSelectingFamilyMember] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const handleClose = () => {
    setGuestData(initialGuestData);
    setOriginalData(initialGuestData);
    setIsSelectingFamilyMember(false);
    setSelectedUser(null);
    onClose();
  };
  useEffect(() => {
    if (!showPopup) {
      return;
    }
    const fetchGuest = async () => {
      try {
        if (!guestId) return;
        const response = await getGuestByMemberId(guestId);
        if (response) {
          const filteredData = Object.keys(initialGuestData).reduce(
            (acc, key) => {
              if (response.hasOwnProperty(key)) {
                if (response[key] === null) {
                  if (
                    key === "times_played" ||
                    key === "times_played_allowed"
                  ) {
                    acc[key] = 0;
                    return acc;
                  }
                  acc[key] = "";
                  return acc;
                }
                acc[key] = response[key];
              }
              return acc;
            },
            {} as Partial<IGuest>
          );
          setGuestData({ ...initialGuestData, ...filteredData });
          setOriginalData({ ...initialGuestData, ...filteredData });
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    };
    fetchGuest();
  }, [guestId]);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const isInput = (element: EventTarget): element is HTMLInputElement =>
      element instanceof HTMLInputElement;

    const { name, value } = e.target;

    let inputValue: string | boolean = value;
    if (isInput(e.target) && e.target.type === "checkbox") {
      inputValue = e.target.checked;
    }
    if (name === "email") inputValue = value.toLowerCase();

    setGuestData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const checkIfDataHasChanged = (
    currentData: IGuest,
    originalData: IGuest
  ): boolean => {
    return (Object.keys(originalData) as Array<keyof IGuest>).some(
      (key) => currentData[key] !== originalData[key]
    );
  };
  useEffect(() => {
    const hasChanged = checkIfDataHasChanged(guestData, originalData);
    setHasGuestDataChanged(hasChanged);
  }, [guestData, originalData]);

  const handleEditGuest = async () => {
    if (!guestData) return;
    try {
      const editResponse = await updateGuest(guestId, guestData);
      if (editResponse && !("message" in editResponse)) {
        toast.success(`Gäst: ${guestData.first_name} uppdaterad`);
        props.onClose();
        props.onConfirm();
      }
    } catch (error: any) {
      toast.error(error.errors?.[0].message);
    }
  };

  const handleDeleteGuest = async () => {
    try {
      const deleteGuestResponse = await deleteGuest(guestId);
      if (deleteGuestResponse && !("message" in deleteGuestResponse)) {
        toast.success(`Gäst: ${guestData.first_name} borttagen`);
      }

      onClose();
      onConfirm();
    } catch (error: any) {
      toast.error(`Error deleting user: ${error.errors?.[0].message}`);
    }
  };
  useEffect(() => {
    if (isSelectingFamilyMember) {
      setShowSearchUserPopup(true);
    } else {
      setSelectedUser(null);
    }
  }, [isSelectingFamilyMember]);

  useEffect(() => {
    if (selectedUser) {
      setGuestData((prevData) => ({
        ...prevData,
        family_member_id: selectedUser.member_id,
      }));
    } else {
      setGuestData((prevData) => ({
        ...prevData,
        family_member_id: "",
      }));
    }
  }, [selectedUser]);

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={handleClose}
      leftTopElement={<span className="text-m">Redigera medlem {guestId}</span>}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleEditGuest();
          }}
        >
          <div className="general-popup-form-group text-m">
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="firstName">Förnamn*</label>
                  <input
                    className="on-transparent"
                    type="text"
                    id="firstName"
                    name="first_name"
                    required
                    placeholder="Förnamn"
                    value={capitalizeWords(guestData?.first_name || "")}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="lastName">Efternamn*</label>
                  <input
                    className="on-transparent"
                    type="text"
                    id="lastName"
                    name="last_name"
                    required
                    placeholder="Efternamn"
                    value={capitalizeWords(guestData?.last_name || "")}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="birthData">Födelsedatum*</label>
                  <input
                    className="on-transparent"
                    type="text"
                    id="birthData"
                    name="social_security_number"
                    placeholder="ÅÅ-MM-DD-XXXX"
                    pattern={socialSecurityRegex}
                    value={guestData.social_security_number}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="phoneNr">Telefon</label>
                  <input
                    className="on-transparent"
                    type="text"
                    id="phoneNr"
                    name="phone_number"
                    placeholder="+46 70-123 4567"
                    pattern={phoneRegex}
                    value={guestData.phone_number}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="gender">Kön</label>
                  <select
                    id="gender"
                    name="gender"
                    required
                    value={guestData.gender}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" disabled>
                      Välj kön
                    </option>
                    <option value="man">Man</option>
                    <option value="kvinna">Kvinna</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="email">E-postadress*</label>
                  <input
                    className="on-transparent"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Ange E-postadress"
                    value={guestData.email}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
            </div>

            <div className="general-popup-form-field">
              <label htmlFor="company">Företag</label>
              <input
                className="on-transparent"
                type="text"
                id="company"
                name="company"
                placeholder="Ange företag"
                value={guestData.company}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="general-popup-form-field">
              <div className="general-popup-form-field-divider">
                <div className="general-popup-form-field-divided-section">
                  <label htmlFor="addres">Adress</label>
                  <input
                    className="on-transparent"
                    type="text"
                    id="adress"
                    name="adress"
                    placeholder="Ange adress"
                    value={guestData.adress}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
            </div>

            {/* <div className="general-popup-form-field">
              <label htmlFor="company">Familjemedlem</label>
              <div className="general-popup-flex-between">
                <input
                  className="on-transparent"
                  type="text"
                  id="company"
                  name="company"
                  disabled
                  placeholder=""
                  value={guestData.family_member_id}
                  onChange={(e) => handleInputChange(e)}
                />

                {guestData.family_member_id && (
                  <div onClick={() => setSelectedUser(null)}>
                    <img
                      src={singleCross}
                      alt="cross"
                      className="cross-img pointer"
                    ></img>
                  </div>
                )}
              </div>
              <div className="general-popup-form-field">
                <button
                  type="button"
                  onClick={() => setShowSearchUserPopup(true)}
                >
                  Välj familjemedlem
                </button>
              </div>
            </div> */}
          </div>

          <div className="general-popup-bottom-buttons-wrapper text-m">
            <button
              className="deleteButton"
              type="button"
              onClick={() => setShowHandleDeleteGuestPopup(true)}
            >
              Radera gäst permanent
            </button>

            <button type="submit" disabled={!hasGuestDataChanged}>
              Redigera gäst
            </button>
          </div>
        </form>
      </div>
      <HandleConfirmationPopup
        showPopup={showHandleDeleteGuestPopup}
        onClose={() => setShowHandleDeleteGuestPopup(false)}
        onConfirm={() => handleDeleteGuest()}
        questionText={`Är du säker på att du vill radera ${guestData.first_name} (${guestId})?`}
        confirmText="Radera gäst permanent"
        denyText="Nej"
      />
      <ChooseUserPopup
        showPopup={showSearchUserPopup}
        onClose={() => {
          setShowSearchUserPopup(false);
        }}
        onConfirm={(user) => {
          setSelectedUser(user);
          setShowSearchUserPopup(false);
        }}
      />
    </Popup>
  );
}

export default EditGuestPopup;
