import "./AdminStatistic.scss";
import React, { useEffect, useRef, useState, useMemo } from "react";
import Chart from "chart.js/auto";
import { getRelativePosition } from "chart.js/helpers";
import { IChartData, ICourtType } from "../../types/api";
import { getChartData } from "../../api/chart/charts";
import { CourtEnum, CourtTypeEnum } from "../../shared/enums";
import WeekDaySelector from "../../hoc/DatePicker/Datepicker";

const chartColors = [
  {
    borderColor: "rgba(75, 192, 192, 1)",
    backgroundColor: "rgba(75, 192, 192, 0.2)",
  },
  {
    borderColor: "rgba(255, 99, 132, 1)",
    backgroundColor: "rgba(255, 99, 132, 0.2)",
  },
  {
    borderColor: "rgba(54, 162, 235, 1)",
    backgroundColor: "rgba(54, 162, 235, 0.2)",
  },
  {
    borderColor: "rgba(255, 206, 86, 1)",
    backgroundColor: "rgba(255, 206, 86, 0.2)",
  },
  {
    borderColor: "rgba(153, 102, 255, 1)",
    backgroundColor: "rgba(153, 102, 255, 0.2)",
  },
  {
    borderColor: "rgba(255, 159, 64, 1)",
    backgroundColor: "rgba(255, 159, 64, 0.2)",
  },
];

function AdminStatistic() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [chartType, setChartType] = useState<"bar" | "line" | "pie">("bar");
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [chartData, setChartData] = useState<IChartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [dates, setDates] = useState<string[]>([]);
  const [courtType, setCourtType] = useState<ICourtType>("INDOOR");
  const [lag, setLag] = useState(3);

  const fetchChartData = async () => {
    setIsLoading(true);
    try {
      const response = await getChartData(dates, courtType, lag);
      if (response) setChartData(response);
    } catch (error: any) {
      console.error("Failed to fetch chart data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const addDateToDates = () => {
    if (
      selectedDate &&
      !dates.includes(selectedDate.toISOString().split("T")[0])
    ) {
      const formattedDate = selectedDate.toISOString().split("T")[0]; // Extract only the date part
      setDates([...dates, formattedDate]);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [courtType, dates, lag]);

  useEffect(() => {}, [selectedDate]);
  useEffect(() => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;
    // Memoize data to avoid unnecessary recomputation
    const data = {
      labels: chartData?.labels || [],
      datasets:
        chartData?.datasets.map((dataset, index) => ({
          label: dataset.label,
          data: dataset.data,
          borderColor: chartColors[index]?.borderColor,
          backgroundColor: chartColors[index]?.backgroundColor,
        })) || [],
    };

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    Chart.defaults.font.family = "Switzer";
    Chart.defaults.font.size = 16;

    const chart = new Chart(ctx, {
      type: chartType,

      data,
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
          },
        },
        onClick: (e) => {
          if (
            !chartInstanceRef.current ||
            !chartInstanceRef.current.scales ||
            !chartInstanceRef.current.scales.x ||
            !chartInstanceRef.current.scales.y
          ) {
            return;
          }
          const canvasPosition = getRelativePosition(e, chart as any);
          const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
          const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
    
        },
      },
    });

    chartInstanceRef.current = chart;

    return () => {
      chart.destroy();
    };
  }, [chartType, chartData]); // Update on chartType or chartData change

  return (
    <div className="admin-handle-statistic-layout">
      <h1 className="text-l primary-text">Statistik</h1>
      <div className="handle-statistic-actions-wrapper">
        <div className="chart-toggle-wrapper text-m">
          <button
            className={`toggle-button ${chartType === "bar" ? "active" : ""}`}
            onClick={() => setChartType("bar")}
          >
            Bar
          </button>
          <button
            className={`toggle-button ${chartType === "line" ? "active" : ""}`}
            onClick={() => setChartType("line")}
          >
            Line
          </button>
          <button
            className={`toggle-button ${chartType === "pie" ? "active" : ""}`}
            onClick={() => setChartType("pie")}
          >
            Pie
          </button>

          <select
            value={courtType}
            onChange={(e) => setCourtType(e.target.value as ICourtType)}
          >
            <option value={"INDOOR"}>Inne</option>
            <option value={"OUTDOOR"}>Ute</option>
          </select>
          <select
            value={lag}
            onChange={(e) => setLag(parseInt(e.target.value))}
          >
            <option value={1}>1 dag</option>
            <option value={2}>2 dagar</option>
            <option value={3}>3 dagar</option>
            <option value={4}>4 dagar</option>
            <option value={5}>5 dagar</option>
            <option value={6}>6 dagar</option>
            <option value={7}>7 dagar</option>
            <option value={8}>8 dagar</option>
            <option value={9}>9 dagar</option>
            <option value={10}>10 dagar</option>
            <option value={11}>11 dagar</option>
            <option value={12}>12 dagar</option>
            <option value={13}>13 dagar</option>
            <option value={14}>14 dagar</option>
            <option value={30}>30 dagar</option>
          </select>
          <WeekDaySelector
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
            includeWeekdays={false}
            textM
            isSmall
          />

          <button onClick={() => addDateToDates()}>Lägg till datum</button>
        </div>
      </div>
      <div className="chart-wrapper">
        {isLoading ? <p>Loading chart...</p> : <canvas ref={canvasRef} />}
      </div>
    </div>
  );
}

export default AdminStatistic;
