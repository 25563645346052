import { useCallback, useEffect, useRef, useState } from "react";
import { IUser } from "../../../types/api";
import MidPopup from "../../../hoc/MidPopup/MidPopup";
import { capitalizeWords, debounce } from "../../../shared/utility";
import { searchUsers } from "../../../api/users/users";
import { toast } from "react-toastify";
import "./ChooseUserPopup.scss";

interface IChooseUserPopupProps {
  onClose: () => void;
  showPopup: boolean;
  onConfirm: (user: IUser) => void;
}
function ChooseUserPopup(props: IChooseUserPopupProps) {
  const { onClose, showPopup, onConfirm } = props;
  const [searchString, setSearchString] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [searchedUsers, setSearchedUsers] = useState<IUser[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setSearchString("");
    setSearchedUsers([]);
    setSelectedUser(null);
    onClose();
  };
  useEffect(() => {
    if (selectedUser) {
      onConfirm(selectedUser);
      handleClose();
    }
  }, [selectedUser]);

  const lastWarningTimeRef = useRef<number>(0);

  const showWarning = useCallback(() => {
    const now = Date.now();
    if (now - lastWarningTimeRef.current >= 5000) {
      lastWarningTimeRef.current = now;
      toast.warning("Inga användare hittades");
    }
  }, []);

  const fetchSearchUsers = async (search: string) => {
    try {
      const response = await searchUsers(search, "admin", true);
      if (response.length === 0) {
        showWarning();
      }

      setSearchedUsers(response);
    } catch (error: any) {
      toast.error(error);
    }
  };
  useEffect(() => {
    if (showPopup && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopup]);

  const handleSearchUsers = () => {
    fetchSearchUsers(searchString);
  };

  useEffect(() => {
    if (searchString.length > 2) {
      handleSearchUsers();
    } else {
      setSearchedUsers([]);
    }
  }, [searchString]);

  const handleChoosePlayer = (player: IUser) => {
    setSelectedUser(player);
  };

  return (
    <MidPopup
      onClose={handleClose}
      showPopup={showPopup}
      leftTopElement={<span className="text-m">Välj medlem</span>}
      onConfirm={() => {
        if (selectedUser) {
          onConfirm(selectedUser);
        }
      }}
    >
      <div className="choose-users-popup-wrapper">
        <div className="user-input-btn-wrapper">
          <div className="search-input-wrapper">
            <input
              className="users-search-input text-m"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              placeholder="Sök på namn & medlemsnummer"
              ref={inputRef}
            ></input>
            <i className="fa-regular fa-magnifying-glass search-icon"></i>
          </div>
        </div>
        {searchedUsers.length > 0 &&
          searchedUsers.map((player, index) => {
            if (index > 4) {
              return null;
            }
            const isFrozen = player.frozen;
            let frozenClass = "";
            if (isFrozen) {
              frozenClass = "frozen";
            }
            return (
              <div
                key={player.member_id}
                className="player-wrapper pointer text-m"
                onClick={() => handleChoosePlayer(player)}
              >
                <div className={`player-name ${frozenClass}`}>
                  <span>
                    {capitalizeWords(player.first_name)}{" "}
                    {capitalizeWords(player.last_name)} ({player.member_type}){" "}
                    {isFrozen && " - medlem fryst"}
                  </span>
                </div>
                <div className="player-id">{player.member_id}</div>
                <a className="choose-player">Välj medlem</a>
              </div>
            );
          })}
      </div>
    </MidPopup>
  );
}

export default ChooseUserPopup;
