export enum ROUTE {
  Index = "/",

  /* Auth */
  Login = "/login",

  ForceChangePassword = "/force-change-password",

  PasswordChange = "/password-change",

  /* Users */
  Users = "/medlemmar",

  /* Memberships */
  Memberships = "/medlemskap",

  /* Tracks */
  Tracks = "/banor",

  /* Bookings */
  Bookings = "/bokningar",
  Activities = "/aktiviteter",
  /* Settings */
  Admin = "/admin",
  Integrations = "/integrationer",
  Settings = "/installningar",

  /* My Profile */
  MyProfile = "/min-profil",

  /* Coach */
  Coach = "/tranare",

  /* Facility */
  Facility = "/hallen",

  /* Opening Times */
  OpeningTimes = "/oppettider",

  /* Reset Password */
  ResetPassword = "/reset-password",

  /* Visual Art Preview */
  VisualArtPreview = "/visual-art-preview",

  /* Statistic */
  Statistic = "/statistik",
}

export enum ROUTE_LABEL {
  Index = "Hem",

  /* Auth */
  Login = "Logga in",

  /* Users */
  Users = "Medlemmar",

  /* Memberships */
  Memberships = "Medlemskap",

  /* Tracks */
  Tracks = "Banornas priser",

  /* Bookings */
  Bookings = "Bokningar",
  Activities = "Aktiviteter",
  /* Settings */
  Admin = "Admin",
  Integrations = "Integrationer",
  Settings = "Inställningar",
  /* My Profile */
  MyProfile = "Profil",

  /* Coach */
  Coach = "Tränare",

  /* Facility */
  Facility = "Banornas öppettider",

  /* Opening Times */
  OpeningTimes = "Öppettider",

  /* Statistic */
  Statistic = "Statistik",
}

export enum Weekday {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}



export enum CourtTypeEnum {
  INDOOR,
  OUTDOOR,
}

export enum BookingEnum {
  IDLE = "IDLE",
  CONTRACT = "CONTRACT",
  DROP_IN = "DROP_IN",
}

export enum StatusEnum {
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  NO_SHOW = "NO_SHOW",
  SEMI_NO_SHOW = "SEMI_NO_SHOW",
  COMPLETED = "COMPLETED",
  CHECKED_IN = "CHECKED_IN",
  PAID = "PAID",
  PAID_NOT_CHECKED_IN = "PAID_NOT_CHECKED_IN",
}

export enum CourtEnum {
  CC = "CC",
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  B4 = "B4",
  B5 = "B5",
  B6 = "B6",
  B7 = "B7",
  B8 = "B8",
  B9 = "B9",
  B10 = "B10",
  B11 = "B11",
  B12 = "B12",
  B14 = "B14",
  B15 = "B15",
}
export enum CourtId {}
//  5008  Cc

//  4918  Bana 1

//  5007  Bana 2

//  4934  Bana 3

//  4919  Bana 4

//  4935  Bana 5

//  4936  Bana 6

//  5318  Bana 7

//  5316  Bana 8

//  5317  Bana 9

//  5300  Bana 10

//  5301  Bana 11

//  5302  Bana 12

//  5304  Bana 14

//  5305  Bana 15

export enum TitleEnum {
  // Hedersledamot = "Hedersledamot",
  // Elitnål = "Elitnål",
  Sponsor = "Sponsor",
  Personal = "Personal",
}
