import { IGuest } from "../../types/api";

export const serachGuestsQueryFn = (search: string) => {
  const query = `
    searchGuest(search: $search) {
         ...on Guest {
          email
          first_name
          last_name
          member_id
          phone_number
          times_played
          family_member
          family_member_id
          gender
         }
          ... on Error {
            code
            message
          }
      }
  `;
  const inputs = `$search: String!`;
  const variables = { search: search };

  return {
    query,
    inputs,
    variables,
  };
};

export const listGuestsQueryFn = (limit: number, start_key: string) => {
  const query = `
    listGuests (limit: $limit, start_key: $start_key){
         ...on displayGuests {
            guests {
              email
              first_name
              last_name
              member_id
              phone_number
              times_played
              family_member
              family_member_id
            }
            next_start_key
          }
          ... on Error {
            code
            message
          }
      }
  `;
  const inputs = `$limit: Int!, $start_key: String!`;
  const variables = { limit: limit, start_key: start_key };
  return {
    query,
    inputs,
    variables,
  };
};

export const getGuestByMemberIdQueryFn = (memberId: string) => {
  const query = `
    getGuestByID(member_id: $member_id) {
         ... on Guest {
          social_security_number
          first_name
          times_played_allowed
          times_played
          postal_code
          phone_number
          member_id
          mass_email
          last_name
          gender
          full_name
          family_member_id
          family_member
          email
          company
          comment
          adress
        }
        ... on Error {
          code
          message
        }
      }
  `;
  const inputs = `$member_id: String!`;
  const variables = { member_id: memberId };
  return {
    query,
    inputs,
    variables,
  };
};

export const exportGuestDataQueryFn = () => {
  const query = `
    exportGuestData {
        ... on DataExport {
          __typename
          url
        }
        ... on Error {
          code
          message
        }
      }
  `;
  return {
    query,
  };
};

export const deleteGuestQueryFn = (member_id: string) => {
  const query = `
      deleteGuest(member_id: $member_id) {
           ... on Guest {
            member_id
          }
          ... on Error {
            code
            message
          }
        }
    `;
  const inputs = `$member_id: String!`;
  const variables = { member_id: member_id };
  return {
    query,
    inputs,
    variables,
  };
};

export const updateGuestQueryFn = (
  member_id: string,
  input_data: Partial<IGuest>
) => {
  const query = `
      updateGuest(member_id: $member_id, input_data: $input_data) {
        ... on Guest {
          first_name
          member_id
          last_name
          email
          phone_number
          times_played
          family_member
          family_member_id
          gender
          full_name
          company

        }
        ... on Error {
          code
          message
        }
      }
    `;
  const inputs = `$member_id: String!, $input_data: GuestInput!`;
  const variables = {
    member_id: member_id,
    input_data: input_data,
  };
  return {
    query,
    inputs,
    variables,
  };
};
