import {
  IBooking,
  ICancelBookingInputType,
  IConfirmBookingSpec,
  IInitiateBookingSpec,
} from "../../types/api";
import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { toast } from "react-toastify";
import {
  AdminCheckInBookingQueryFn,
  AdminReportNoShowQueryFn,
  getActiveBookingsByMemberQueryFn,
  getBookingsByMemberQueryFn,
  getCoPlayerBookingsByMemberQueryFn,
  moveBookingQueryFn,
  updateBookingCommentQueryFn,
  updateBookingQueryFn,
} from "./bookingsQueries";
import { StatusEnum } from "../../shared/enums";

export const confirmBooking = async (
  bookingToConfirm: IConfirmBookingSpec,
  group: string,
  send_email?: boolean,
  drop_in?: boolean
) => {
  const query = `mutation MyMutation($input: ConfirmBooking, $group: String!, $send_email:Boolean, $drop_in: Boolean, ) {
    confirmBooking(input: $input, group: $group,send_email:$send_email, drop_in: $drop_in) {
      ... on Booking {
        court_id
        updated_at
        booking_type
        final_price {
          event
          price
        }
      }
      ... on Error {
        code
        message
      }
    }
  }`;

  try {
    const getConfirmResponse = (await API.graphql({
      query: query,
      variables: {
        input: bookingToConfirm,
        group: group,
        send_email: send_email,
        drop_in: drop_in,
      },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getConfirmResponse.data.confirmBooking;
    if ("message" in response) {
      toast.error(`${response.message}`);
      return response;
    }
    return response;
  } catch (error: any) {
    toast.error(`${error.errors[0].message}`);
    return false;
  }
};

export const initiateBooking = async (
  bookingToInitiate: IInitiateBookingSpec
) => {
  const query = `mutation MyMutation($input: BookingInput!) {
  initiateBooking(input: $input) {
    ... on Booking {
      court_id
      updated_at
      booking_id
      booking_type
      datetime_start
      final_price {
        event
        price
      }
      guests {
        member_id
        status
      }
      co_players {
        member_id
        status
      }
      coach_id
      member_id
      member_status
      member_name
      status
      slot_index
      created_by
      created_at
    }
    ... on Error {
      code
      message
    }
  }
}`;

  try {
    const getInitiateResponse = (await API.graphql({
      query: query,
      variables: { input: bookingToInitiate },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getInitiateResponse.data.initiateBooking;
    if ("message" in response) {
      toast.error(`${response.message}`);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    return error;
  }
};

export const getActiveBookingsByMember = async (
  member_id: string,
  co_player: boolean
) => {
  const { query, variables, inputs } =
    getActiveBookingsByMemberQueryFn(member_id);
  const myQuery = `query MyQuery(${inputs}) {
      ${query}
  }`;

  try {
    const getBookingsResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;

    const response = getBookingsResponse.data.getActiveBookingsByMember;

    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const expireBooking = async (input: ICancelBookingInputType) => {
  const query = `mutation MyMutation($input: CancelBookingInputType!) {
    expireBooking(input: $input) {
      ... on CancelBookingType {
        booking_id
        court_id
        datetime_start

      }
      ... on Error {
        code
        message
      }
    }
  }`;
  try {
    const getExpireResponse = (await API.graphql({
      query: query,
      variables: { input: input },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getExpireResponse.data.expireBooking;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const cancelBooking = async (
  input: ICancelBookingInputType,
  requestor: "admin" | "user",
  moved: boolean = false,
  due_to_rain: boolean = false
) => {
  let admin = false;
  if (requestor === "admin") {
    admin = true;
  }
  const query = `mutation MyMutation($input: CancelBookingInputType!, $admin: Boolean!, $moved: Boolean, $due_to_rain: Boolean) {
    cancelBooking(input: $input, admin: $admin, moved: $moved, due_to_rain: $due_to_rain) {
      ... on CancelBookingType {
        booking_id
        court_id
        datetime_start
      }
      ... on Error {
        code  
        message
      }
    }
  }`;
  try {
    const getCancelResponse = (await API.graphql({
      query: query,
      variables: {
        input: input,
        admin: admin,
        moved: moved,
        due_to_rain: due_to_rain,
      },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as GraphQLResult<any>;

    const response = getCancelResponse.data.cancelBooking;
    if ("message" in response) {
      toast.error(response.message);
      return response;
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const AdminCheckInBooking = async (
  booking_id: string,
  date: string,
  member_id: string,
  type: string,
  status: StatusEnum,
  family_member: boolean
) => {
  const { query, inputs, variables } = AdminCheckInBookingQueryFn(
    booking_id,
    date,
    member_id,
    type,
    status,
    family_member
  );
  const myQuery = `mutation MyMutation(${inputs}) {
        ${query}
    }`;
  try {
    const getCheckInResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getCheckInResponse.data.AdminCheckInBooking;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const AdminReportNoShow = async (booking_id: string, date: string) => {
  const { query, inputs, variables } = AdminReportNoShowQueryFn(
    booking_id,
    date
  );
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;
  try {
    const getNoShowResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getNoShowResponse.data.AdminReportNoshow;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getBookingsByMember = async (member_id: string) => {
  const { query, variables, inputs } = getBookingsByMemberQueryFn(member_id);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;
  try {
    const getBookingsResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getBookingsResponse.data.getBookingsByMember;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const getCoPlayerBookingsByMember = async (member_id: string) => {
  const { query, variables, inputs } =
    getCoPlayerBookingsByMemberQueryFn(member_id);
  const myQuery = `query MyQuery(${inputs}) {
    ${query}
  }`;
  try {
    const getBookingsResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getBookingsResponse.data.getCoPlayerBookingsByMember;
    if ("message" in response) {
      console.error(response.message);
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateBooking = async (
  booking: IBooking,
  removed_players: string[],
  added_players: string[],
  removed_guests: string[],
  added_guests: string[],
  requestor: "admin" | "user",
  drop_in: boolean,
  removed_coach_id?: string,
  added_coach_id?: string
) => {
  const { query, inputs, variables } = updateBookingQueryFn(
    booking,
    removed_players,
    added_players,
    removed_guests,
    added_guests,
    requestor,
    drop_in,
    removed_coach_id,
    added_coach_id
  );
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;
  try {
    const getUpdateResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;
    const response = getUpdateResponse.data.updateBooking;
    if ("message" in response) {
      toast.dismiss();
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const updateBookingComment = async (
  comment: string,
  court_id: string,
  date: string,
  slot_index: number
) => {
  const { query, inputs, variables } = updateBookingCommentQueryFn(
    comment,
    court_id,
    date,
    slot_index
  );
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;

  try {
    const getUpdateResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;

    const response = getUpdateResponse.data.updateBookingComment;

    if ("message" in response) {
      toast.dismiss();
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};

export const moveBooking = async (
  new_booking: IConfirmBookingSpec,
  old_datetime_start: string,
  old_court_id: string
) => {
  const { query, inputs, variables } = moveBookingQueryFn(
    new_booking,
    old_datetime_start,
    old_court_id
  );
  const myQuery = `mutation MyMutation(${inputs}) {
    ${query}
  }`;

  try {
    const getMoveResponse = (await API.graphql({
      query: myQuery,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      variables: variables,
    })) as GraphQLResult<any>;

    const response = getMoveResponse.data.moveBooking;
    if ("message" in response) {
      toast.dismiss();
      toast.error(response.message);
    }
    return response;
  } catch (error: any) {
    error.errors ? toast.error(error.errors[0].message) : toast.error(error);
    throw error;
  }
};
