import { useEffect, useState } from "react";
import Popup from "../../../hoc/Popup/Popup";
import { IBooking, ICoach, ICoachAvailability } from "../../../types/api";
import { toast } from "react-toastify";
import "./CoachHandleAvailabilityPopup.scss";
import { makeCoachAvailable } from "../../../api/coaches/coaches";
import useCoachAvailabilityBatches, {
  ICoachAvailabilityBatch,
} from "../../../shared/hooks/useCoachAvailabilityBatches";
import CoachAvailabilityBatch from "../../../hoc/CoachAvailabilityBatch/CoachAvailabilityBatch";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../../../shared/dateUtils";
import { startOfWeek } from "date-fns";
import plus from "../../../assets/images/plus.svg";

interface ICoachHandleAvailabilityPopupProps {
  showPopup: boolean;
  onClose: () => void;
  onConfirm: () => void;
  coach: ICoach;
  coachAvailability: ICoachAvailability[];
  filterDate: Date;
  slotToBeRemoved?: any;
  slotToBeAdded?: any;
}

interface IntervalDetail {
  start_time: string;
  end_time: string;
  price: string;
  booking?: IBooking;
}

interface GroupedIntervals {
  [date: string]: IntervalDetail[];
}

function CoachHandleAvailabilityPopup(
  props: ICoachHandleAvailabilityPopupProps
) {
  const { coach, coachAvailability, filterDate } = props;
  const firstDayOfWeek = startOfWeek(new Date(filterDate), { weekStartsOn: 1 });
  const [removalFlag, setRemovalFlag] = useState(false);
  const [additionFlag, setAdditionFlag] = useState(false);
  const [popupInitiated, setPopupInitiated] = useState(false);
  const [bookedIntervals, setBookedIntervals] = useState<
    ICoachAvailabilityBatch[]
  >([]);
  const [initialIntervals, setInitialIntervals] = useState<
    ICoachAvailabilityBatch[]
  >([]);
  const initialBatches = [
    {
      id: uuidv4(),
      startTime: "07:00:00",
      endTime: "23:00:00",
      date: firstDayOfWeek,
      price: 0,
      booking: null,
    },
  ];
  const {
    coachAvailabilityBatches: intervals,
    addCoachAvailabilityBatch: addNewBatch,
    removeCoachAvailabilityBatch: removeBatch,
    setBatches: setBatches,
    updateStartTime: updateStartTime,
    updateEndTime: updateEndTime,
    updateDate: updateDate,
  } = useCoachAvailabilityBatches([]);

  useEffect(() => {
    if (props.showPopup) {
      let unbookedBatches: any = [];
      let bookedBatches: any = [];

      if (
        coachAvailability &&
        coachAvailability.some(
          (availability) => availability.intervals.length > 0
        )
      ) {
        coachAvailability.forEach((weekdayBatch) => {
          const unbooked: any = [];
          const booked: any = [];

          weekdayBatch.intervals.forEach((interval) => {
            const intervalData = {
              id: uuidv4(),
              startTime: interval.start_time,
              endTime: interval.end_time,
              date: new Date(weekdayBatch.date),
              price: interval.price,
              booking: interval.booking,
            };

            if (interval.booking === null) {
              unbooked.push(intervalData);
            } else {
              booked.push(intervalData);
            }
          });

          if (unbooked.length > 0) {
            unbookedBatches.push({ intervals: unbooked });
          }
          if (booked.length > 0) {
            bookedBatches.push({ intervals: booked });
          }
        });

        setBatches(
          unbookedBatches.flatMap(
            (batch: ICoachAvailability) => batch.intervals
          )
        );
        setBookedIntervals(() => [
          ...bookedBatches.flatMap(
            (batch: ICoachAvailability) => batch.intervals
          ),
        ]);
        setInitialIntervals(
          unbookedBatches.flatMap(
            (batch: ICoachAvailability) => batch.intervals
          )
        );
      } else {
        setBatches(initialBatches);
        setInitialIntervals(initialBatches);
      }
      setPopupInitiated(true);
    }
  }, [props.showPopup, coachAvailability]);

  const handleSlotRemoval = async (slot: string, date: Date) => {
    try {
      const [slotStartTime, slotEndTime] = slot
        .split(" - ")
        .map((time) => time + ":00");

      // Find the interval that contains the specified slot
      const targetInterval = intervals.find(
        (interval) =>
          formatDate(interval.date, "yyyy-MM-dd") ===
            formatDate(date, "yyyy-MM-dd") &&
          interval.startTime <= slotStartTime &&
          interval.endTime >= slotEndTime
      );

      if (!targetInterval) {
        toast.error("Slot does not exist in the selected intervals.");
        return;
      }

      const remainingIntervals = intervals.filter(
        (interval) => interval.id !== targetInterval.id
      );

      // Split the interval if needed
      const newIntervals = [];
      const intervalStartTime = new Date(
        `1970-01-01T${targetInterval.startTime}`
      );
      const intervalEndTime = new Date(`1970-01-01T${targetInterval.endTime}`);
      const slotStart = new Date(`1970-01-01T${slotStartTime}`);
      const slotEnd = new Date(`1970-01-01T${slotEndTime}`);

      // Create a new interval for the time before the slot
      if (intervalStartTime < slotStart) {
        newIntervals.push({
          ...targetInterval,
          endTime: slotStartTime,
        });
      }

      // Create a new interval for the time after the slot
      if (intervalEndTime > slotEnd) {
        newIntervals.push({
          ...targetInterval,
          startTime: slotEndTime,
          id: uuidv4(), // Generate a new ID for the new interval
        });
      }

      setBatches([...remainingIntervals, ...newIntervals]);
      setRemovalFlag(true);
    } catch (error: any) {
      console.error("Error removing slot:", error);
      toast.error("Failed to remove slot. Please try again.");
    }
  };
  const handleSlotAddition = async (slot: string, date: Date) => {
    try {
      const [slotStartTime, slotEndTime] = slot
        .split(" - ")
        .map((time) => time + ":00");

      // Convert input times to Date objects
      const slotStart = new Date(`1970-01-01T${slotStartTime}`);
      const slotEnd = new Date(`1970-01-01T${slotEndTime}`);

      // Filter intervals for the same date and without bookings
      const relevantIntervals = intervals.filter(
        (interval) =>
          formatDate(interval.date, "yyyy-MM-dd") ===
            formatDate(date, "yyyy-MM-dd") && !interval.booking
      );

      // Find intervals that are adjacent or overlapping
      const overlappingIntervals = relevantIntervals.filter((interval) => {
        const intervalStart = new Date(`1970-01-01T${interval.startTime}`);
        const intervalEnd = new Date(`1970-01-01T${interval.endTime}`);
        return (
          intervalStart <= slotEnd && intervalEnd >= slotStart // Overlapping intervals
        );
      });

      // Determine the new start and end times for the merged interval
      const newStartTime = overlappingIntervals.length
        ? overlappingIntervals.reduce(
            (earliest, interval) =>
              new Date(`1970-01-01T${interval.startTime}`) < earliest
                ? new Date(`1970-01-01T${interval.startTime}`)
                : earliest,
            slotStart
          )
        : slotStart;

      const newEndTime = overlappingIntervals.length
        ? overlappingIntervals.reduce(
            (latest, interval) =>
              new Date(`1970-01-01T${interval.endTime}`) > latest
                ? new Date(`1970-01-01T${interval.endTime}`)
                : latest,
            slotEnd
          )
        : slotEnd;

      // Remove overlapping intervals
      const remainingIntervals = intervals.filter(
        (interval) => !overlappingIntervals.includes(interval)
      );

      // Add the new merged interval
      const newInterval = {
        id: uuidv4(),
        startTime: newStartTime.toTimeString().slice(0, 8),
        endTime: newEndTime.toTimeString().slice(0, 8),
        date: date,
        price: coach.coach_price || 0,
        booking: null,
      };

      setBatches([...remainingIntervals, newInterval]);
      setAdditionFlag(true);
    } catch (error: any) {
      console.error("Error adding slot:", error);
      toast.error("Failed to add slot. Please try again.");
    }
  };

  useEffect(() => {
    if (popupInitiated && props.slotToBeRemoved) {
      const { slot, date } = props.slotToBeRemoved;
      handleSlotRemoval(slot, new Date(date));
    }
    if (popupInitiated && props.slotToBeAdded) {
      const { slot, date } = props.slotToBeAdded;
      handleSlotAddition(slot, new Date(date));
    }
  }, [popupInitiated, props.slotToBeRemoved, props.slotToBeAdded]);

  const handleClose = () => {
    setBatches(initialBatches);
    setPopupInitiated(false);
    setAdditionFlag(false);
    setRemovalFlag(false);
    props.onClose();
  };

  const checkForOverlaps = (intervals: ICoachAvailabilityBatch[]) => {
    for (let i = 0; i < intervals.length; i++) {
      for (let j = i + 1; j < intervals.length; j++) {
        if (
          intervals[i].date.getTime() === intervals[j].date.getTime() &&
          ((intervals[i].startTime < intervals[j].endTime &&
            intervals[i].startTime >= intervals[j].startTime) ||
            (intervals[i].endTime > intervals[j].startTime &&
              intervals[i].endTime <= intervals[j].endTime))
        ) {
          return true;
        }
      }
    }
    return false;
  };
  useEffect(() => {
    if (removalFlag || additionFlag) {
      handleCoachUpdateAvailability();
    }
   
  }, [removalFlag, additionFlag]);

  const handleCoachUpdateAvailability = async () => {
    try {
      const allIntervals = [...intervals, ...bookedIntervals];

      if (checkForOverlaps(allIntervals)) {
        toast.error("Interval överlappar");
        return;
      }

      // Identify changed intervals
      const changedDates = new Set<string>();

      intervals.forEach((current) => {
        const original = initialIntervals.find(
          (init) => init.id === current.id
        );
        if (
          !original ||
          original.startTime !== current.startTime ||
          original.endTime !== current.endTime ||
          original.price !== current.price
        ) {
          changedDates.add(formatDate(current.date, "yyyy-MM-dd"));
        }
      });

      initialIntervals.forEach((init) => {
        if (!intervals.find((current) => current.id === init.id)) {
          changedDates.add(formatDate(init.date, "yyyy-MM-dd"));
        }
      });

      if (changedDates.size === 0) {
        toast.info("Inga förändringar att uppdatera");
        return;
      }

      // Group all intervals by date
      const groupedByDate: GroupedIntervals = allIntervals.reduce(
        (acc, batch) => {
          const dateKey = formatDate(batch.date, "yyyy-MM-dd");
          if (!acc[dateKey]) {
            acc[dateKey] = [];
          }
          acc[dateKey].push({
            start_time: batch.startTime,
            end_time: batch.endTime,
            price: batch.price.toString(),
            booking: batch.booking ? batch.booking : undefined,
          });
          return acc;
        },
        {} as GroupedIntervals
      );

      // Ensure that all changed dates are included
      changedDates.forEach((dateKey) => {
        if (!groupedByDate[dateKey]) {
          groupedByDate[dateKey] = [];
        }
      });

      // Send availability updates for changed dates only
      const availabilityPromises = Array.from(changedDates).map((date) => {
        const availabilityToUpdate = {
          coach_id: props.coach.coach_id,
          date: date,
          intervals: groupedByDate[date] || [],
        };
        return makeCoachAvailable(availabilityToUpdate);
      });

      const responses = await Promise.all(availabilityPromises);
      if (responses && !responses.some((response) => !response)) {
        toast.success("Tillgänglighet uppdaterad");
        setInitialIntervals([]);
        props.onConfirm();
        handleClose();
      }
    } catch (error: any) {
      console.error("Error creating coach:", error);

      const errorMessage =
        error.message || "An unexpected error occurred. Please try again.";
      toast.error(`Error creating coach: ${errorMessage}`);
    }
  };

  return (
    <Popup
      showPopup={
        props.showPopup && !props.slotToBeRemoved && !props.slotToBeAdded
      }
      onClose={handleClose}
      leftTopElement={`Tillgänglighet -  ${props.coach.first_name}`}
    >
      <div className="general-popup-wrapper">
        <form
          className="general-popup-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleCoachUpdateAvailability();
          }}
        >
          <div className="general-popup-form-group">
            <div className="general-popup-form-field">
              <label htmlFor="memberType" className="text-s">
                Tillgänglighet
              </label>
              {intervals.map((batch, index) => (
                <CoachAvailabilityBatch
                  key={batch.id}
                  startTime={batch.startTime || "07:00:00"}
                  endTime={batch.endTime || "23:00:00"}
                  onAdd={() => addNewBatch()}
                  date={batch.date}
                  onRemove={() => removeBatch(batch.id)}
                  onStartTimeChange={(newStartTime) =>
                    updateStartTime(batch.id, newStartTime)
                  }
                  onEndTimeChange={(newEndTime) =>
                    updateEndTime(batch.id, newEndTime)
                  }
                  onDateChange={(newDate) => {
                    updateDate(batch.id, newDate);
                  }}
                  isFirst={false}
                  price={coach.coach_price}
                />
              ))}
              {intervals.length === 0 && (
                <div className="add-new-availability-batch-wrapper">
                  <img
                    src={plus}
                    className="pointer"
                    onClick={() => addNewBatch()}
                    alt="Add new time batch"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="general-popup-bottom-buttons-wrapper flex-end">
            <button type="submit" className="text-m">
              Uppdatera tillgänglighet
            </button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

export default CoachHandleAvailabilityPopup;
